import { useContext } from "react";
import { has } from "lodash";
import ApplicationCardItem from "./ApplicationCardItem";
// import Applicant1DetailsForm from "./application-forms/Applicant1DetailsForm";
import MortgageDetailsForm from "./application-forms/MortgageDetailsForm";
import CurrentPropertyDetailsForm from "./application-forms/CurrentPropertyDetailsForm";
import Applicant1EmploymentForm from "./application-forms/Applicant1EmploymentForm";
import Applicant2EmploymentForm from "./application-forms/Applicant2EmploymentForm";

import capitalizeEachWord from "../utils/capitalizeEachWord";
import application from "../data/application.json";
import AppContext from "../Context/AppContext";

const EmploymentCardItems = () => {
  const { applicationData } = useContext(AppContext);

  const { forms } = application.cards.employment;

  return (
    <>
      <ApplicationCardItem
        formTitle={forms.applicant1Employment.formTitle}
        form={<Applicant1EmploymentForm />}
      />

      {applicationData.jointApplication ? (
        <ApplicationCardItem
          formTitle={`${
            has(applicationData.applicant2Details, "firstName") &&
            applicationData.applicant2Details.firstName !== ""
              ? capitalizeEachWord(applicationData.applicant2Details.firstName)
              : "Other applicant"
          }'s employment details`}
          form={<Applicant2EmploymentForm />}
        />
      ) : null}
    </>
  );
};

export default EmploymentCardItems;
