const ApplicationCard = ({ title, cardItems }) => {
  return (
    <div className="mb-8 rounded-lg border border-gray-200 bg-white p-4 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black dark:shadow-purple-500/50">
      <h2 className="mb-4 text-2xl">{title}</h2>

      {cardItems}
    </div>
  );
};

export default ApplicationCard;
