import { useContext, useEffect, useState } from "react";
import { has } from "lodash";
import SelectMenu from "../form/SelectMenu";
import InputField from "../form/InputField";
import { ButtonDefault } from "../Buttons";
import ApplicationPreview from "../ApplicationPreview";
import AppContext from "../../Context/AppContext";
import application from "../../data/application.json";

const Applicant1DetailsForm = () => {
  const { applicationData, setApplicationData, titleOptions, modal, setModal } =
    useContext(AppContext);

  const [data, setData] = useState({});
  const [formDataChange, setFormDataChange] = useState({});
  // const [errors, setErrors] = useState({});

  useEffect(() => {
    const { applicant1Details } = { ...applicationData };

    const title =
      has(applicant1Details, "title") && applicant1Details.title !== null
        ? applicant1Details.title
        : null;

    const firstName =
      has(applicant1Details, "firstName") && applicant1Details.firstName
        ? applicant1Details.firstName
        : null;

    const lastName =
      has(applicant1Details, "lastName") && applicant1Details.lastName
        ? applicant1Details.lastName
        : null;

    const dateOfBirth =
      has(applicant1Details, "dateOfBirth") && applicant1Details.dateOfBirth
        ? applicant1Details.dateOfBirth
        : null;

    const placeOfBirth =
      has(applicant1Details, "placeOfBirth") && applicant1Details.placeOfBirth
        ? applicant1Details.placeOfBirth
        : null;

    const nationality =
      has(applicant1Details, "nationality") && applicant1Details.nationality
        ? applicant1Details.nationality
        : null;

    setData({
      title,
      firstName,
      lastName,
      dateOfBirth,
      placeOfBirth,
      nationality,
    });
  }, []);

  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value,
    };
    doAfterChange(input);
  };

  const doAfterChange = (input) => {
    setData({
      ...data,
      [input.name]: input.value,
    });
    setFormDataChange(input);
  };

  const handleSave = () => {
    handleModalClose();
    setApplicationData({
      ...applicationData,
      applicant1Details: data,
    });
  };

  const handleModalClose = () => {
    modal.modalOpenedFrom === "preview"
      ? setModal({
          modalOpen: true,
          modalType: "preview",
          modalTitle: "Preview application",
          modalContent: <ApplicationPreview />,
        })
      : setModal({ modalOpen: false });
  };

  const { formFields } = application.cards.details.forms.applicant1Details;

  return (
    <div className="rounded-lg bg-white p-8 text-center text-black">
      <p className="mb-4 text-4xl">{modal.modalTitle}</p>
      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm ">
          <SelectMenu
            name="title"
            label={formFields.title.label}
            options={titleOptions}
            //hiddenOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
            db={true}
            help={null}
            value={data.title}
            onChange={handleChange}
            //   error={errors.title}
          />
        </div>
      </div>
      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm ">
          <InputField
            name="firstName"
            label={formFields.firstName.label}
            type="text"
            help={null}
            value={data.firstName}
            onChange={handleChange}
            //   error={errors["firstName"]}
          />
        </div>
      </div>
      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm ">
          <InputField
            name="lastName"
            label={formFields.lastName.label}
            type="text"
            help={null}
            value={data.lastName}
            onChange={handleChange}
            //   error={errors["lastName"]}
          />
        </div>
      </div>
      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm ">
          <InputField
            name="dateOfBirth"
            label={formFields.dateOfBirth.label}
            type="text"
            help={null}
            value={data.dateOfBirth}
            onChange={handleChange}
            //   error={errors["lastName"]}
          />
        </div>
      </div>
      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm ">
          <InputField
            name="placeOfBirth"
            label={formFields.placeOfBirth.label}
            type="text"
            help={null}
            value={data.placeOfBirth}
            onChange={handleChange}
            //   error={errors["lastName"]}
          />
        </div>
      </div>
      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm ">
          <InputField
            name="nationality"
            label={formFields.nationality.label}
            type="text"
            help={null}
            value={data.nationality}
            onChange={handleChange}
            //   error={errors["lastName"]}
          />
        </div>
      </div>
      <div className="flex justify-center pt-4">
        <div className="w-full max-w-sm ">
          <div className="flex items-center">
            <span className="mr-4">
              <ButtonDefault
                color="emerald"
                label="Save"
                onClick={handleSave}
              />
            </span>

            <ButtonDefault
              color="white"
              label="Cancel"
              onClick={handleModalClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applicant1DetailsForm;
