import { useContext } from "react";
import FormApplication from "../components/FormApplication.jsx";
import Title from "../components/Title.jsx";
import ApplicationCards from "../components/ApplicationCards.jsx";
import { ButtonLarge } from "../components/Buttons.jsx";
import ApplicationPreview from "../components/ApplicationPreview.jsx";
import AppContext from "../Context/AppContext";

const ApplicationPage = () => {
  const { setModal } = useContext(AppContext);

  return (
    <div className="mx-auto mb-32 mt-12 max-w-5xl px-4 md:mb-16 md:px-8">
      <div className="mb-8">
        <Title title="Start my mortgage" stage="3" />
      </div>

      <div className="mb-4 flex w-full justify-end">
        <ButtonLarge
          color="plain"
          label="Preview application"
          onClick={() =>
            setModal({
              modalOpen: true,
              modalType: "preview",
              modalTitle: "Preview your application",
              modalContent: <ApplicationPreview />,
            })
          }
        />
      </div>

      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        <div className="mx-auto w-full max-w-lg">
          <div className="frost mb-8 rounded-lg border border-gray-200 bg-white/50 p-4 pb-0 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black/50 dark:shadow-purple-500/50">
            <FormApplication />
          </div>
          <div className="flex justify-start gap-4">
            <ButtonLarge
              color="emerald"
              label="Submit application"
              onClick={() => console.log("submit application!")}
            />

            <ButtonLarge
              color="plain"
              label="Save for later"
              onClick={() => console.log("save for later!")}
            />
          </div>
        </div>

        <div className="mx-auto w-full max-w-lg">
          <ApplicationCards />
        </div>
      </div>
    </div>
  );
};

export default ApplicationPage;
