import { useContext, useEffect, useState } from "react";
import { has, isEmpty } from "lodash";
import Joi from "joi";

import SelectMenu from "../form/SelectMenu";
import InputField from "../form/InputField";
import { ButtonDefault } from "../Buttons";

import { validateProperty } from "../../utils/validation";

import ApplicationPreview from "../ApplicationPreview";
import AppContext from "../../Context/AppContext";

import application from "../../data/application.json";

const Applicant1ContactDetailsForm = () => {
  const { applicationData, setApplicationData, titleOptions, modal, setModal } =
    useContext(AppContext);

  const [data, setData] = useState({});
  const [formDataChange, setFormDataChange] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const { applicant1ContactDetails } = { ...applicationData };

    const email =
      has(applicant1ContactDetails, "email") &&
      applicant1ContactDetails.email !== null
        ? applicant1ContactDetails.email
        : null;

    const phone =
      has(applicant1ContactDetails, "phone") && applicant1ContactDetails.phone
        ? applicant1ContactDetails.phone
        : null;

    setData({
      email,
      phone,
    });
  }, []);

  useEffect(() => {
    // VALIDATE PROPERTY
    if (!isEmpty(formDataChange))
      validateProperty(formDataChange, schemaObj, errors, setErrors);
  }, [formDataChange]);

  const schemaObj = {
    email: Joi.string().email({ tlds: false }).allow(null, "").messages({
      "string.email": "Please enter a valid email address",
    }),
    phone: Joi.string().allow(null, ""),
  };

  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value,
    };
    doAfterChange(input);
  };

  const doAfterChange = (input) => {
    setData({
      ...data,
      [input.name]: input.value,
    });
    setFormDataChange(input);
  };

  const handleSave = () => {
    handleModalClose();
    setApplicationData({
      ...applicationData,
      applicant1ContactDetails: data,
    });
  };

  const handleModalClose = () => {
    modal.modalOpenedFrom === "preview"
      ? setModal({
          modalOpen: true,
          modalType: "preview",
          modalTitle: "Preview application",
          modalContent: <ApplicationPreview />,
        })
      : setModal({ modalOpen: false });
  };

  const { formFields } =
    application.cards.details.forms.applicant1ContactDetails;

  return (
    <div className="rounded-lg bg-white p-8 text-center text-black">
      <p className="mb-4 text-4xl">{modal.modalTitle}</p>
      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm ">
          <InputField
            name="email"
            label={formFields.email.label}
            type="text"
            help={null}
            value={data.email}
            onChange={handleChange}
            error={errors.email}
          />
        </div>
      </div>
      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm ">
          <InputField
            name="phone"
            label={formFields.phone.label}
            type="text"
            help={null}
            value={data.phone}
            onChange={handleChange}
            error={errors.phone}
          />
        </div>
      </div>
      <div className="flex justify-center pt-4">
        <div className="w-full max-w-sm ">
          <div className="flex items-center">
            <span className="mr-4">
              <ButtonDefault
                color="emerald"
                label="Save"
                onClick={handleSave}
              />
            </span>

            <ButtonDefault
              color="white"
              label="Cancel"
              onClick={handleModalClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applicant1ContactDetailsForm;
