import { useContext } from "react";
import { has } from "lodash";
import ApplicationCardItem from "./ApplicationCardItem";
// import Applicant1DetailsForm from "./application-forms/Applicant1DetailsForm";
import MortgageDetailsForm from "./application-forms/MortgageDetailsForm";
import CurrentPropertyDetailsForm from "./application-forms/CurrentPropertyDetailsForm";

import capitalizeEachWord from "../utils/capitalizeEachWord";
import application from "../data/application.json";
import AppContext from "../Context/AppContext";

const MortgageCardItems = () => {
  const { applicationData } = useContext(AppContext);

  const { forms } = application.cards.mortgage;

  return (
    <>
      <ApplicationCardItem
        formTitle={forms.mortgageDetails.formTitle}
        form={<MortgageDetailsForm />}
      />

      {!applicationData.firstTimeBuyer && applicationData.propertyToSell ? (
        <ApplicationCardItem
          formTitle={forms.currentPropertyDetails.formTitle}
          form={<CurrentPropertyDetailsForm />}
        />
      ) : null}
    </>
  );
};

export default MortgageCardItems;
