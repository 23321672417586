import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { has, orderBy } from "lodash";

import NumberFormat from "react-number-format";
import ResultsBadge from "./ResultsBadge";
import { ButtonSmall } from "../Buttons";

import CallToActions from "../CallToActions";

import { calculateMortgage } from "../../utils/calculators";
//import settings from "../../data/settings.json";
// import { priceFormatter } from "../../utils/PriceFormatter";
import AppContext from "../../Context/AppContext";

function MortgageCalculator({
  formData,
  setFormData,
  calculatedLaonAmount,
  calculatedPropertyPurchasePrice,
}) {
  const { rates, lowestRates, showResults, search } = useContext(AppContext);

  const [calculatorData, setCalculatorData] = useState({
    calcMortgageLoanAmount: calculatedLaonAmount ? calculatedLaonAmount : null,
    calcMortgageTerm: 30,
    calcInterestRate: null,
  });
  const [calculatorResults, setCalculatorResults] = useState({});
  const [calculatorDataChange, setCalculatorDataChange] = useState(true);

  // useEffect(() => {
  //   if (calculatedLaonAmount && calculatedPropertyPurchasePrice) {
  //     const lowestFoundRate = getLowestInterestRate();

  //     if (lowestFoundRate && !calculatorDataChange) {
  //       const data = { ...calculatorData };

  //       data.calcInterestRate = lowestFoundRate;

  //       setCalculatorData(data);
  //     }

  //     setCalculatorDataChange(false);
  //   }
  // }, [calculatorResults]);

  useEffect(() => {
    // const data = { ...calculatorData };

    // if (search) {
    //   if (formData.buyToLet) console.log("btl lowest rate");
    //   if (formData.schemes === "sharedOwnership") console.log("SO lowest rate");
    //   if (!formData.buyToLet && formData.schemes !== "sharedOwnership");
    // }

    setFormData({ term: calculatorData.calcMortgageTerm });
  }, []);

  useEffect(() => {
    if (calculatedLaonAmount && formData.schemes !== "sharedOwnership") {
      const data = { ...calculatorData };

      data.calcMortgageLoanAmount = calculatedLaonAmount;
      data.calcInterestRate = getLowestInterestRate();

      setCalculatorData(data);
      setCalculatorResults(
        calculateMortgage(
          data.calcMortgageLoanAmount,
          data.calcMortgageTerm,
          data.calcInterestRate
        )
      );
    }
  }, [calculatedPropertyPurchasePrice, showResults]);

  const getLowestInterestRate = () => {
    const loanToValue =
      (calculatedLaonAmount / calculatedPropertyPurchasePrice) * 100;

    // console.log("1-LTV", loanToValue);

    /// move to utils
    const ltvLimit = !formData.buyToLet ? 95 : 80;

    if (loanToValue <= ltvLimit) {
      const getRateGroup = () => {
        if (formData.schemes !== "sharedOwnership" && !formData.buyToLet)
          return "residential";
        if (formData.schemes === "sharedOwnership") return "sharedOwnership";
        if (formData.buyToLet) return "buyToLet";
      };

      const rateGroup = getRateGroup();

      //console.log("2-Rate Group", rateGroup);

      let filteredRates = [...rates].filter((item) => item[rateGroup]);

      filteredRates = filteredRates[0][rateGroup].filter(
        (item) => item.maxLtv >= loanToValue
      );

      const orderedRates = orderBy(filteredRates, "rate", "asc");

      const lowestFoundRate = orderedRates.find((item) => {
        return item.rate > 0;
      });

      // console.log("3-Lowest Rate mortgage", lowestFoundRate);

      return lowestFoundRate ? lowestFoundRate.rate : null;
    }
    //else {
    //   return null;
    // }
  };

  const handleRangeChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value.replace(/[^0-9.-]+/g, ""),
      min: currentTarget.min,
      max: currentTarget.max,
      maxLength: currentTarget.maxLength,
    };

    if (input.min && input.max) {
      input.value =
        Number(input.value) > Number(input.max)
          ? input.max
          : input.value !== "" && Number(input.value) < Number(input.min)
          ? input.min
          : input.value;
    }

    if (input.value.length > input.maxLength)
      input.value = input.value.substr(0, input.maxLength);

    let data = { ...calculatorData };

    const convertedValue = isNaN(parseFloat(input.value))
      ? 0
      : parseFloat(input.value);

    data[input.name] = convertedValue;

    setCalculatorData(data);
    setCalculatorDataChange(true);
    setCalculatorResults(
      calculateMortgage(
        data.calcMortgageLoanAmount,
        data.calcMortgageTerm,
        data.calcInterestRate
      )
    );
    if (input.name === "calcMortgageTerm")
      setFormData({ term: data.calcMortgageTerm });
  };

  const handleReset = () => {
    const data = { ...calculatorData };

    data.calcMortgageLoanAmount = calculatedLaonAmount;
    data.calcMortgageTerm = 30;
    data.calcInterestRate = !formData.buyToLet
      ? lowestRates.residential
      : lowestRates.buyToLet;

    setCalculatorData(data);
    setCalculatorResults(
      calculateMortgage(
        data.calcMortgageLoanAmount,
        data.calcMortgageTerm,
        data.calcInterestRate
      )
    );
    setFormData({ term: data.calcMortgageTerm });
  };

  const { calcMortgageLoanAmount, calcMortgageTerm, calcInterestRate } =
    calculatorData;

  const incrementButtons = (name, increment, maxLength, min, max) => {
    const handleMinus = (name, increment, maxLength) => {
      const data = { ...calculatorData };

      const incrementValue = data[name] - increment;
      const convertedValue = incrementValue.toString().substr(0, maxLength);
      data[name] = Number(convertedValue);

      setCalculatorData(data);
      setCalculatorDataChange(true);
      setCalculatorResults(
        calculateMortgage(
          data.calcMortgageLoanAmount,
          data.calcMortgageTerm,
          data.calcInterestRate
        )
      );
      if (name === "calcMortgageTerm")
        setFormData({ term: data.calcMortgageTerm });
    };
    const handlePlus = (name, increment) => {
      const data = { ...calculatorData };

      const incrementValue = data[name] + increment;
      const convertedValue = incrementValue.toString().substr(0, maxLength);
      data[name] = Number(convertedValue);

      setCalculatorData(data);
      setCalculatorDataChange(true);
      setCalculatorResults(
        calculateMortgage(
          data.calcMortgageLoanAmount,
          data.calcMortgageTerm,
          data.calcInterestRate
        )
      );
      if (name === "calcMortgageTerm")
        setFormData({ term: data.calcMortgageTerm });
    };

    return (
      <div className="mt-1 flex w-full justify-between">
        <button
          type="button"
          className="inline-flex items-center rounded-full bg-gray-100 px-4  py-2 text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white disabled:bg-gray-100/50 disabled:text-black/25 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600  dark:focus:ring-offset-black dark:disabled:bg-gray-600/50	dark:disabled:text-white/25"
          disabled={calculatorData[name] <= min}
          onClick={() => handleMinus(name, increment, maxLength)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          type="button"
          className="inline-flex items-center rounded-full bg-gray-100 px-4  py-2 text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white disabled:bg-gray-100/50 disabled:text-black/25 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600  dark:focus:ring-offset-black dark:disabled:bg-gray-600/50	dark:disabled:text-white/25"
          disabled={calculatorData[name] >= max}
          onClick={() => handlePlus(name, increment, maxLength)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    );
  };

  return (
    <>
      {formData.schemes !== "sharedOwnership" ? (
        <div className="mb-8 rounded-lg border border-gray-200 bg-white p-4 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black dark:shadow-purple-500/50">
          {/* <div className="bg-gradient-to-tl from-blue-500 via-sky-500 to-cyan-500 shadow-md shadow-purple-500/25 dark:shadow-purple-500/50 rounded-lg p-4 mb-8"> */}
          <h2 className="mb-4 text-3xl leading-none text-black dark:text-white">
            Mortgage repayments
          </h2>

          <>
            {/* {calcMortgageLoanAmount && calculatorResults[0] ? ( */}
            <div className="mb-0 rounded-lg border border-gray-400 bg-gradient-to-tl from-gray-100 p-2 dark:border-gray-600 dark:bg-gray-900 dark:from-gray-900">
              {/* <div className="relative"> */}
              <ResultsBadge
                size="large"
                format="price"
                colorScheme={2}
                result={
                  showResults && calculatedLaonAmount ? calculatorResults[0] : 0
                }
                label="Monthly capital and interest"
                help={
                  <p className="text-md text-white">
                    You pay off the mortgage interest and part of the capital of
                    your loan each month. This is the only type of mortgage
                    which guarantees that you will pay off the mortgage by the
                    end of the term.
                  </p>
                }
                last={formData.schemes === "helpToBuy" ? true : false}
              />
              {/* <p className="absolute top-0 right-0">I</p>
          </div> */}

              {formData.schemes !== "helpToBuy" ? (
                <ResultsBadge
                  size="large"
                  format="price"
                  colorScheme={2}
                  result={
                    showResults && calculatedLaonAmount
                      ? calculatorResults[1]
                      : 0
                  }
                  label="Monthly interest only"
                  help={
                    <p className="text-md text-white">
                      Your mortgage loan will remain the same and you will only
                      pay the interest each month. You will need to arrange full
                      payment of your mortgage loan at the end of the term.
                    </p>
                  }
                  last={true}
                />
              ) : null}

              {/* {calculatedLaonAmount ? (
                <div className="mt-4">
                  <CallToActions />
                </div>
              ) : null} */}
            </div>
            {/* ) : null} */}
          </>
          {showResults && calculatedLaonAmount ? (
            <>
              <div className="mt-4">
                <label
                  htmlFor="calcMortgageLoanAmountNumber"
                  className="text-md mb-1 block font-medium text-black dark:text-white"
                >
                  Mortgage loan amount
                </label>
                <div className="flex items-center">
                  {/* //////// */}
                  <div className="flex grow flex-col items-start">
                    <input
                      type="range"
                      name="calcMortgageLoanAmount"
                      id="calcMortgageLoanAmountRange"
                      maxLength={9}
                      min={500}
                      max={
                        Math.round((calculatedLaonAmount + 100000) / 500) * 500
                      }
                      step={500}
                      value={
                        calcMortgageLoanAmount ? calcMortgageLoanAmount : ""
                      }
                      className="h-3 w-full appearance-none overflow-hidden rounded-lg border border-gray-400 bg-gray-100 focus:shadow-none focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-900"
                      onChange={handleRangeChange}
                    />
                  </div>
                  {/* //////// */}
                  <NumberFormat
                    //type={type}
                    name="calcMortgageLoanAmount"
                    id="calcMortgageLoanAmountNumber"
                    maxLength={9}
                    min={0}
                    max={
                      Math.round((calculatedLaonAmount + 100000) / 500) * 500
                    }
                    value={
                      calcMortgageLoanAmount === 0 ? "" : calcMortgageLoanAmount
                    }
                    className="text-md ml-4 block w-[8rem] rounded-md border border-gray-400 bg-white font-bold text-black focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-black dark:text-white  "
                    onChange={handleRangeChange}
                    //displayType={"text"}
                    thousandSeparator={true}
                    prefix={"£"}
                    decimalScale={2}
                    //allowEmptyFormatting={true}
                    allowNegative={false}
                  />
                </div>
                {incrementButtons(
                  "calcMortgageLoanAmount",
                  500,
                  9,
                  500,
                  Math.round((calculatedLaonAmount + 100000) / 500) * 500
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="calcMortgageTermNumber"
                  className="text-md mb-1 block font-medium text-black dark:text-white"
                >
                  Mortgage term (years)
                </label>
                <div className="flex items-center">
                  <input
                    type="range"
                    name="calcMortgageTerm"
                    id="calcMortgageTermRange"
                    maxLength={2}
                    min={1}
                    max={40}
                    value={calcMortgageTerm ? calcMortgageTerm : ""}
                    className="h-3 w-full appearance-none overflow-hidden rounded-lg border border-gray-400 bg-gray-100 focus:shadow-none focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-900"
                    onChange={handleRangeChange}
                  />
                  <input
                    type="number"
                    name="calcMortgageTerm"
                    id="calcMortgageTermNumber"
                    maxLength={2}
                    min={1}
                    max={40}
                    value={calcMortgageTerm === 0 ? "" : calcMortgageTerm}
                    className="text-md ml-4 block w-[6rem] rounded-md border border-gray-400 bg-white font-bold text-black focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-black dark:text-white  "
                    onChange={handleRangeChange}
                  />
                </div>

                {incrementButtons("calcMortgageTerm", 1, 2, 1, 40)}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="calcInterestRateNumber"
                  className="text-md mb-1 block font-medium text-black dark:text-white"
                >
                  Interest rate (%)
                </label>
                <div className="flex items-center">
                  <input
                    type="range"
                    name="calcInterestRate"
                    id="calcInterestRateRange"
                    maxLength={4}
                    min={0.01}
                    max={10}
                    step={0.01}
                    value={
                      calcInterestRate
                        ? calcInterestRate
                        : !formData.buyToLet
                        ? lowestRates.residential
                        : lowestRates.buyToLet
                    }
                    className="h-3 w-full appearance-none overflow-hidden rounded-lg border border-gray-400 bg-gray-100 focus:shadow-none focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-900"
                    onChange={handleRangeChange}
                  />
                  <input
                    type="number"
                    name="calcInterestRate"
                    id="calcInterestRateNumber"
                    maxLength={4}
                    min={0.01}
                    max={10}
                    step={0.01}
                    value={
                      Number(calcInterestRate)
                        ? Number(calcInterestRate)
                        : !formData.buyToLet
                        ? lowestRates.residential
                        : lowestRates.buyToLet
                    }
                    className="text-md ml-4 block w-[6rem] rounded-md border border-gray-400 bg-white font-bold text-black focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-black dark:text-white  "
                    onChange={handleRangeChange}
                  />
                </div>
                {incrementButtons("calcInterestRate", 0.1, 4, 0.01, 10)}
              </div>

              <div className="mb-0 flex justify-end">
                <button
                  type="button"
                  className="flex items-center rounded-full bg-gray-100 px-4 py-2 text-sm font-bold leading-none text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600	dark:focus:ring-offset-black"
                  onClick={() => handleReset()}
                >
                  Reset
                </button>
              </div>
            </>
          ) : null}

          {/* <div>
            <p className="text-xs text-black dark:text-white">
              The above results can only be used as a guide. For an exact
              borrowing figure please contact us and we will provide a full
              review for you.
            </p>
          </div> */}
        </div>
      ) : null}
    </>
  );
}

export default MortgageCalculator;
