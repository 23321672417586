import { useContext } from "react";
import { has } from "lodash";
import ApplicationPreviewList from "./ApplicationPreviewList";
import Applicant1DetailsForm from "./application-forms/Applicant1DetailsForm";
import Applicant2DetailsForm from "./application-forms/Applicant2DetailsForm";
import Applicant1ContactDetailsForm from "./application-forms/Applicant1ContactDetailsForm";
import Applicant2ContactDetailsForm from "./application-forms/Applicant2ContactDetailsForm";
import Applicant1CurrentAddressForm from "./application-forms/Applicant1CurrentAddressForm";
import Applicant2CurrentAddressForm from "./application-forms/Applicant2CurrentAddressForm";
import ApplicantsDependentsForm from "./application-forms/ApplicantsDependentsForm";
import AppContext from "../Context/AppContext";
import application from "../data/application.json";
import Applicant1PreviewList from "./Applicant1PreviewList";
import Applicant2PreviewList from "./Applicant2PreviewList";
import capitalizeEachWord from "../utils/capitalizeEachWord";

const ApplicationPreview = () => {
  const { applicationData, modal } = useContext(AppContext);

  // const capitalizeEachWord = (string) => {
  //   return string
  //     .split(/\s+/)
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(" ");
  // };

  const { formFields } = application.cards.details.forms.applicant1Details;

  const {
    applicant1Details,
    applicant2Details,
    applicant1ContactDetails,
    applicant2ContactDetails,
    applicant1CurrentAddress,
    applicant2CurrentAddress,
    applicantsDependents,
  } = applicationData;

  return (
    <div className="rounded-lg bg-white p-8 text-center text-black">
      <p className="mb-4 text-4xl">{modal.modalTitle}</p>

      <Applicant1PreviewList />

      <Applicant2PreviewList />

      <ApplicationPreviewList
        title="Your contact details"
        form={<Applicant1ContactDetailsForm />}
        listItems={[
          {
            itemName: "Email",
            itemValue: has(applicant1ContactDetails, "email")
              ? applicant1ContactDetails.email
              : null,
          },
          {
            itemName: "Phone",
            itemValue: has(applicant1ContactDetails, "phone")
              ? applicant1ContactDetails.phone
              : null,
          },
        ]}
      />

      <ApplicationPreviewList
        title={`${
          has(applicationData.applicant2Details, "firstName") &&
          applicationData.applicant2Details.firstName !== ""
            ? capitalizeEachWord(applicationData.applicant2Details.firstName)
            : "Other applicant"
        }'s contact details`}
        form={<Applicant2ContactDetailsForm />}
        listItems={[
          {
            itemName: "Email",
            itemValue: has(applicant2ContactDetails, "email")
              ? applicant2ContactDetails.email
              : null,
          },
          {
            itemName: "Phone",
            itemValue: has(applicant2ContactDetails, "phone")
              ? applicant2ContactDetails.phone
              : null,
          },
        ]}
      />

      <ApplicationPreviewList
        title="Your current address"
        form={<Applicant1CurrentAddressForm />}
        listItems={[
          {
            itemName: "Current address",
            itemValue: has(applicant1CurrentAddress, "currentAddress")
              ? applicant1CurrentAddress.currentAddress
              : null,
          },
          {
            itemName: "Date moved in",
            itemValue: has(applicant1CurrentAddress, "moveInDate")
              ? applicant1CurrentAddress.moveInDate
              : null,
          },
        ]}
      />

      <ApplicationPreviewList
        title={`${
          has(applicationData.applicant2Details, "firstName") &&
          applicationData.applicant2Details.firstName !== ""
            ? capitalizeEachWord(applicationData.applicant2Details.firstName)
            : "Other applicant"
        }'s current address`}
        form={<Applicant2CurrentAddressForm />}
        listItems={[
          {
            itemName: "Current address",
            itemValue: has(applicant2CurrentAddress, "currentAddress")
              ? applicant2CurrentAddress.currentAddress
              : null,
          },
          {
            itemName: "Date moved in",
            itemValue: has(applicant2CurrentAddress, "moveInDate")
              ? applicant2CurrentAddress.moveInDate
              : null,
          },
        ]}
      />
      <ApplicationPreviewList
        title="Your dependents"
        form={<ApplicantsDependentsForm />}
        listItems={[
          {
            itemName: "Number of dependent children",
            itemValue: has(applicantsDependents, "dependents")
              ? applicantsDependents.dependents
              : null,
          },
        ]}
      />
    </div>
  );
};

export default ApplicationPreview;
