// import { useContext } from "react";
// import { has } from "lodash";
// import { ButtonSmall } from "./Buttons";

import ApplicationCard from "./ApplicationCard";
// import ApplicationCardItem from "./ApplicationCardItem";
// import Applicant1DetailsForm from "./application-forms/Applicant1DetailsForm";
// import Applicant2DetailsForm from "./application-forms/Applicant2DetailsForm";
// import Applicant1ContactDetailsForm from "./application-forms/Applicant1ContactDetailsForm";
// import Applicant2ContactDetailsForm from "./application-forms/Applicant2ContactDetailsForm";
// import Applicant1CurrentAddressForm from "./application-forms/Applicant1CurrentAddressForm";
// import Applicant2CurrentAddressForm from "./application-forms/Applicant2CurrentAddressForm";
// import ApplicantsDependentsForm from "./application-forms/ApplicantsDependentsForm";
import DetailsCardItems from "./DetailsCardItems";
import MortgageCardItems from "./MortgageCardItems";
import EmploymentCardItems from "./EmploymentCardItems";
import OutgoingsCardItems from "./OutgoingsCardItems";

// import AppContext from "../Context/AppContext";
import application from "../data/application.json";
//i//mport capitalizeEachWord from "../utils/capitalizeEachWord";

const ApplicationCards = () => {
  //// const { applicationData } = useContext(AppContext);

  // const { forms } = application.cards.details;

  return (
    <>
      <ApplicationCard
        title={application.cards.details.cardTitle}
        cardItems={<DetailsCardItems />}
      />

      <ApplicationCard
        title={application.cards.mortgage.cardTitle}
        cardItems={<MortgageCardItems />}
      />

      <ApplicationCard
        title={application.cards.employment.cardTitle}
        cardItems={<EmploymentCardItems />}
      />

      <ApplicationCard
        title={application.cards.outgoings.cardTitle}
        cardItems={<OutgoingsCardItems />}
      />
    </>
  );
};

export default ApplicationCards;
