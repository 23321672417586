import { useContext, useEffect, useState } from "react";
import { has, isEmpty } from "lodash";
import Joi from "joi";

import InputField from "../form/InputField";
import { ButtonDefault } from "../Buttons";

import { validateProperty } from "../../utils/validation";

import ApplicationPreview from "../ApplicationPreview";
import AppContext from "../../Context/AppContext";
import RadioGroup from "../form/RadioGroup";

import application from "../../data/application.json";

const ApplicantsDependentsForm = () => {
  const { applicationData, setApplicationData, modal, setModal } =
    useContext(AppContext);

  const [data, setData] = useState({});
  const [formDataChange, setFormDataChange] = useState({});
  //const [errors, setErrors] = useState({});

  useEffect(() => {
    const { applicantsDependents } = { ...applicationData };

    const dependents =
      has(applicantsDependents, "dependents") &&
      applicantsDependents.dependents !== null
        ? applicantsDependents.dependents
        : null;

    setData({
      dependents,
    });
  }, []);

  //   useEffect(() => {
  //     // VALIDATE PROPERTY
  //     if (!isEmpty(formDataChange))
  //       validateProperty(formDataChange, schemaObj, errors, setErrors);
  //   }, [formDataChange]);

  //   const schemaObj = {
  //     email: Joi.string().email({ tlds: false }).allow(null, "").messages({
  //       "string.email": "Please enter a valid email address",
  //     }),
  //     phone: Joi.string().allow(null, ""),
  //   };

  const handleChange = ({ currentTarget }) => {
    //console.log(currentTarget.type);
    const input = {
      name: currentTarget.name,
      value:
        currentTarget.type === "text"
          ? currentTarget.value
          : Number(currentTarget.value),
    };
    doAfterChange(input);
  };

  const doAfterChange = (input) => {
    setData({
      ...data,
      [input.name]: input.value,
    });
    setFormDataChange(input);
  };

  const handleSave = () => {
    handleModalClose();
    setApplicationData({
      ...applicationData,
      applicantsDependents: data,
    });
  };

  const handleModalClose = () => {
    modal.modalOpenedFrom === "preview"
      ? setModal({
          modalOpen: true,
          modalType: "preview",
          modalTitle: "Preview application",
          modalContent: <ApplicationPreview />,
        })
      : setModal({ modalOpen: false });
  };

  const { formFields } = application.cards.details.forms.applicantsDependents;

  return (
    <div className="rounded-lg bg-white p-8 text-center text-black">
      <p className="mb-4 text-4xl">{modal.modalTitle}</p>
      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm ">
          <RadioGroup
            name="dependents"
            label={formFields.dependents.label}
            options={[
              { _id: 0, value: 0, option: "None" },
              { _id: 1, value: 1, option: "1" },
              { _id: 2, value: 2, option: "2" },
              { _id: 3, value: 3, option: "3" },
              { _id: 4, value: 4, option: "4" },
              { _id: 5, value: 5, option: "5" },
              { _id: 6, value: 6, option: "5+" },
            ]}
            help={null}
            value={data.dependents}
            onChange={handleChange}
            // error={errors.currentAddress}
          />
        </div>
      </div>
      <div className="flex justify-center pt-4">
        <div className="w-full max-w-sm ">
          <div className="flex items-center">
            <span className="mr-4">
              <ButtonDefault
                color="emerald"
                label="Save"
                onClick={handleSave}
              />
            </span>

            <ButtonDefault
              color="white"
              label="Cancel"
              onClick={handleModalClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantsDependentsForm;
