import { useContext, useEffect, useState } from "react";
import { has } from "lodash";
import SelectMenu from "../form/SelectMenu";
import InputField from "../form/InputField";
import InputPriceField from "../form/InputPriceField";
import RadioGroup from "../form/RadioGroup";
import { ButtonDefault } from "../Buttons";
import ApplicationPreview from "../ApplicationPreview";
import AppContext from "../../Context/AppContext";
import application from "../../data/application.json";
import LabelCheckMark from "../form/LabelCheckMark";

const MortgageDetailsForm = () => {
  const {
    applicationData,
    setApplicationData,
    calculatedPropertyPurchasePrice,
    titleOptions,
    modal,
    setModal,
    showResults,
    formData,
  } = useContext(AppContext);

  const [data, setData] = useState({});
  const [formDataChange, setFormDataChange] = useState({});
  // const [errors, setErrors] = useState({});

  useEffect(() => {
    const { mortgageDetails } = { ...applicationData };

    const sharedOwnershipPercentage = has(
      mortgageDetails,
      "sharedOwnershipPercentage"
    )
      ? mortgageDetails.sharedOwnershipPercentage
      : showResults && formData.sharedOwnershipPercentage
      ? formData.sharedOwnershipPercentage
      : 40;

    const firstHomesGreaterLondon = has(
      mortgageDetails,
      "firstHomesGreaterLondon"
    )
      ? mortgageDetails.firstHomesGreaterLondon
      : showResults && has(formData, "firstHomesGreaterLondon")
      ? formData.firstHomesGreaterLondon
      : null;

    const buyToLet = has(mortgageDetails, "buyToLet")
      ? mortgageDetails.buyToLet
      : showResults && has(formData, "buyToLet")
      ? formData.buyToLet
      : null;

    const additionalPropertyPurchase = has(
      mortgageDetails,
      "additionalPropertyPurchase"
    )
      ? mortgageDetails.additionalPropertyPurchase
      : showResults && has(formData, "additionalPropertyPurchase")
      ? formData.additionalPropertyPurchase
      : null;

    const propertyPurchasePrice = has(mortgageDetails, "propertyPurchasePrice")
      ? mortgageDetails.propertyPurchasePrice
      : showResults && calculatedPropertyPurchasePrice
      ? calculatedPropertyPurchasePrice
      : null;

    const deposit = has(mortgageDetails, "deposit")
      ? mortgageDetails.deposit
      : showResults && formData.deposit
      ? formData.deposit
      : null;

    setData({
      sharedOwnershipPercentage,
      firstHomesGreaterLondon,
      buyToLet,
      additionalPropertyPurchase,
      propertyPurchasePrice,
      deposit,
    });
  }, []);

  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value,
    };
    doAfterChange(input);
  };

  const handlePriceChange = ({ currentTarget }) => {
    const input = {
      value: Number(currentTarget.value.replace(/[^0-9.-]+/g, "")),
      name: currentTarget.name,
    };
    doAfterChange(input);
  };

  const handleRangeChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value.replace(/[^0-9.-]+/g, ""),
      min: currentTarget.min,
      max: currentTarget.max,
      maxLength: currentTarget.maxLength,
    };

    if (input.min && input.max) {
      input.value =
        Number(input.value) > Number(input.max)
          ? input.max
          : input.value !== "" && Number(input.value) < Number(input.min)
          ? input.min
          : input.value;
    }

    if (input.value.length > input.maxLength)
      input.value = input.value.substr(0, input.maxLength);

    input.value = isNaN(parseFloat(input.value)) ? 0 : parseFloat(input.value);

    doAfterChange(input);
  };

  const incrementButtons = (name, increment, maxLength, min, max) => {
    const handleMinus = (name, increment, maxLength) => {
      const incrementValue = data[name] - increment;
      const convertedValue = incrementValue.toString().substr(0, maxLength);
      data[name] = Number(convertedValue);

      doAfterChange({ name, value: data[name] });
    };
    const handlePlus = (name, increment) => {
      const incrementValue = data[name] + increment;
      const convertedValue = incrementValue.toString().substr(0, maxLength);
      data[name] = Number(convertedValue);

      doAfterChange({ name, value: data[name] });
    };

    return (
      <div className="mt-1 flex w-full justify-between">
        <button
          type="button"
          className="inline-flex items-center rounded-full bg-gray-100 px-4  py-2 text-black  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white disabled:bg-gray-100/50 disabled:text-black/25 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700  dark:focus:ring-gray-600 dark:focus:ring-offset-black	dark:disabled:bg-gray-600/50 dark:disabled:text-white/25"
          disabled={formData[name] <= min}
          onClick={() => handleMinus(name, increment, maxLength)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          type="button"
          className="inline-flex items-center rounded-full bg-gray-100 px-4  py-2 text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white disabled:bg-gray-100/50 disabled:text-black/25 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600  dark:focus:ring-offset-black dark:disabled:bg-gray-600/50	dark:disabled:text-white/25"
          disabled={formData[name] >= max}
          onClick={() => handlePlus(name, increment, maxLength)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    );
  };

  const handleRadioChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: parseInt(currentTarget.value),
    };
    doAfterChange(input);
  };

  const doAfterChange = (input) => {
    setData({
      ...data,
      [input.name]: input.value,
    });
    setFormDataChange(input);
  };

  const handleSave = () => {
    handleModalClose();
    setApplicationData({
      ...applicationData,
      mortgageDetails: data,
    });
  };

  const handleModalClose = () => {
    modal.modalOpenedFrom === "preview"
      ? setModal({
          modalOpen: true,
          modalType: "preview",
          modalTitle: "Preview application",
          modalContent: <ApplicationPreview />,
        })
      : setModal({ modalOpen: false });
  };

  const { formFields } = application.cards.mortgage.forms.mortgageDetails;

  return (
    <div className="rounded-lg bg-white p-8 text-center text-black">
      <p className="mb-4 text-4xl">{modal.modalTitle}</p>

      {/* <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm">Buying a home</div>
      </div>

      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm">Single / Joint purchase</div>
      </div> */}

      {applicationData.schemes === "sharedOwnership" ? (
        <div className="mb-4 flex justify-center">
          <div className="w-full max-w-sm">
            <div className="flex items-start">
              <LabelCheckMark value={data.sharedOwnershipPercentage} />
              <label
                htmlFor="sharedOwnershipPercentageRange"
                className="text-md block text-left font-medium text-black dark:text-white"
              >
                What is the share percentage? (%)
              </label>
            </div>

            <div className="flex items-center">
              <input
                type="range"
                name="sharedOwnershipPercentage"
                id="sharedOwnershipPercentageRange"
                maxLength="3"
                min="1"
                max="100"
                step="1"
                value={data.sharedOwnershipPercentage}
                className="h-3 w-full appearance-none overflow-hidden rounded-lg border border-gray-400 bg-gray-100 focus:shadow-none focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-900"
                onChange={handleRangeChange}
              />
              <input
                type="number"
                name="sharedOwnershipPercentage"
                id="sharedOwnershipPercentageNumber"
                maxLength={3}
                min={1}
                max={100}
                step={1}
                value={data.sharedOwnershipPercentage}
                className="text-md ml-4 block w-[6rem] rounded-md border border-gray-400 bg-white font-bold text-black focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-black dark:text-white  "
                onChange={handleRangeChange}
              />
            </div>
            {incrementButtons("sharedOwnershipPercentage", 1, 3, 1, 100)}
            {/* {errors.sharedOwnershipPercentage && (
            <div className="mt-1">
              <Alert type="danger" text={errors.sharedOwnershipPercentage} />
            </div>
          )} */}
          </div>
        </div>
      ) : null}

      {applicationData.propertyRegion === "10" &&
      applicationData.schemes === "firstHomes" ? (
        <div className="mb-4 flex justify-center">
          <div className="w-full max-w-sm">
            <RadioGroup
              name="firstHomesGreaterLondon"
              label="Is the property in Greater London?"
              options={[
                { _id: 0, value: 1, option: "Yes" },
                { _id: 1, value: 0, option: "No" },
              ]}
              toolTip={null}
              value={data.firstHomesGreaterLondon}
              onChange={handleRadioChange}
              //error={errors["firstHomesGreaterLondon"]}
            />
          </div>
        </div>
      ) : null}

      {applicationData.scheme === 0 ? (
        <div className="mb-4 flex justify-center">
          <div className="w-full max-w-sm">
            <RadioGroup
              name="buyToLet"
              label="Are you purchasing a buy to let property?"
              options={[
                { _id: 0, value: 1, option: "Yes" },
                { _id: 1, value: 0, option: "No" },
              ]}
              toolTip={null}
              value={data.buyToLet}
              onChange={handleRadioChange}
              //error={errors["buyToLet"]}
            />
          </div>
        </div>
      ) : null}

      {applicationData.firstTimeBuyer === 0 && data.buyToLet === 0 ? (
        <div className="mb-4 flex justify-center">
          <div className="w-full max-w-sm">
            <RadioGroup
              name="additionalPropertyPurchase"
              label="Is this an additional property purchase?"
              options={[
                { _id: 0, value: 1, option: "Yes" },
                { _id: 1, value: 0, option: "No" },
              ]}
              toolTip={null}
              value={data.additionalPropertyPurchase}
              onChange={handleRadioChange}
              //error={errors["additionalPropertyPurchase"]}
            />
          </div>
        </div>
      ) : null}

      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm">
          <InputPriceField
            name="propertyPurchasePrice"
            label={formFields.propertyPurchasePrice.label}
            type="text"
            help={null}
            value={data.propertyPurchasePrice}
            onChange={handlePriceChange}
            //   error={errors["firstName"]}
          />
        </div>
      </div>

      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm">
          <InputPriceField
            name="deposit"
            label={formFields.deposit.label}
            type="text"
            help={null}
            value={data.deposit}
            onChange={handlePriceChange}
            //   error={errors["firstName"]}
          />
        </div>
      </div>
      <div className="flex justify-center pt-4">
        <div className="w-full max-w-sm ">
          <div className="flex items-center">
            <span className="mr-4">
              <ButtonDefault
                color="emerald"
                label="Save"
                onClick={handleSave}
              />
            </span>

            <ButtonDefault
              color="white"
              label="Cancel"
              onClick={handleModalClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MortgageDetailsForm;
