import { useContext, useEffect, useState } from "react";
import Joi, { object } from "joi";
import { forEach, has, isEmpty, merge, omit, remove } from "lodash";

import RadioGroup from "./form/RadioGroup";
import SelectMenu from "./form/SelectMenu";

import { ButtonDefault } from "./Buttons";

import { validateProperty } from "../utils/validation";

import AppContext from "../Context/AppContext";

const FormApplication = () => {
  const {
    formData,
    applicationData,
    setApplicationData,
    propertyRegionOptions,
    schemeOptions: originalSchemeData,
    modal,
    setModal,
  } = useContext(AppContext);

  const [defaultSchemeOptions, setDefaultSchemeOptions] = useState([]);
  const [schemeOptions, setSchemeOptions] = useState([]);
  const [formDataChange, setFormDataChange] = useState({});
  const [reloadForm, setReloadForm] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const data = { ...applicationData };

    data.mortgageType =
      has(data, "mortgageType") && data.mortgageType !== null
        ? data.jointApplication
        : null;

    data.jointApplication =
      has(data, "jointApplication") && data.jointApplication !== null
        ? data.jointApplication
        : has(formData, "jointApplication") &&
          formData.jointApplication !== null
        ? formData.jointApplication
        : null;

    data.propertyRegion =
      has(data, "propertyRegion") && data.propertyRegion !== null
        ? data.propertyRegion
        : has(formData, "propertyRegion") &&
          formData.propertyRegion !== null &&
          formData.propertyRegion !== "0"
        ? formData.propertyRegion
        : null;

    data.firstTimeBuyer =
      has(data, "firstTimeBuyer") && data.firstTimeBuyer !== null
        ? data.firstTimeBuyer
        : has(formData, "firstTimeBuyer") && formData.firstTimeBuyer !== null
        ? formData.firstTimeBuyer
        : null;

    data.scheme =
      has(data, "scheme") && data.scheme !== null
        ? data.scheme
        : has(formData, "scheme") && formData.scheme !== null
        ? formData.scheme
        : null;

    data.schemes =
      has(data, "schemes") && data.schemes !== null
        ? data.schemes
        : has(formData, "schemes") && formData.schemes !== null
        ? formData.schemes
        : null;

    data.propertyToSell =
      has(data, "propertyToSell") && data.propertyToSell !== null
        ? data.propertyToSell
        : has(formData, "propertyToSell") && formData.propertyToSell !== null
        ? formData.propertyToSell
        : null;

    setApplicationData(data);
  }, []);

  useEffect(() => {
    const updatedSchemes = merge(originalSchemeData, optionsTooltip);
    setDefaultSchemeOptions(updatedSchemes);

    const schemeOptionsClone = [...updatedSchemes];
    formData.propertyRegion !== "11" &&
      remove(schemeOptionsClone, { value: "helpToBuy" });
    setSchemeOptions(schemeOptionsClone);
  }, []);

  useEffect(() => {
    setReloadForm(false);
  }, [reloadForm]);

  useEffect(() => {
    if (!isEmpty(formDataChange))
      validateProperty(formDataChange, schemaObj, errors, setErrors);

    const { name, value } = formDataChange;

    if (name === "propertyRegion") {
      const options = [...defaultSchemeOptions];

      if (value !== "11") {
        if (applicationData.firstTimeBuyer) {
          //remove(options, { _id: 2 });
          remove(options, { value: "helpToBuy" });

          setSchemeOptions(options);
        } else {
          // remove(options, (item) => {
          //   return item._id !== 1;
          // });
          remove(options, (item) => {
            return item.value !== "sharedOwnership";
          });

          setSchemeOptions(options);
        }

        if (applicationData.schemes === "helpToBuy")
          setApplicationData({ schemes: null });
      } else {
        if (applicationData.firstTimeBuyer) {
          setSchemeOptions(options);
        } else {
          // remove(options, (item) => {
          //   return item._id !== 1;
          // });
          remove(options, (item) => {
            return item.value !== "sharedOwnership";
          });

          setSchemeOptions(options);
        }
      }
    }

    if (name === "firstTimeBuyer") {
      const options = [...defaultSchemeOptions];

      if (value === 0) {
        // remove(options, (item) => {
        //   return item._id !== 1;
        // });
        remove(options, (item) => {
          return item.value !== "sharedOwnership";
        });

        setSchemeOptions(options);

        setApplicationData({
          // helpToBuy: null,
          // firstHomes: null,
          schemes:
            applicationData.schemes === "sharedOwnership"
              ? "sharedOwnership"
              : null,
          mortgageDetails: {
            ...applicationData.mortgageDetails,
            firstHomesGreaterLondon: null,
          },
        });
      } else {
        if (applicationData.propertyRegion !== "11") {
          // remove(options, { _id: 2 });
          remove(options, { value: "helpToBuy" });

          setSchemeOptions(options);
        } else {
          setSchemeOptions(defaultSchemeOptions);
        }

        setApplicationData({
          //additionalPropertyPurchase: null,
          propertyToSell: null,
          //propertyToSellValue: null,
        });

        // setAlerts({
        //   ...alerts,
        //   deposit:
        //     applicationData.schemes === "sharedOwnership"
        //       ? sharedOwnershipDepositAlert(equity)
        //       : null,
        //   equityDeposit: null,
        // });
      }
    }

    if (name === "scheme") {
      if (value === 1)
        setApplicationData({
          mortgageDetails: {
            ...applicationData.mortgageDetails,
            buyToLet: null,
          },
        });
      else {
        setApplicationData({
          schemes: null,
          mortgageDetails: {
            ...applicationData.mortgageDetails,
            firstHomesGreaterLondon: null,
          },
          // propertyPurchasePrice: null,
        });
        //setAlerts({ ...alerts, deposit: null });
      }
    }

    ////////////

    console.log("name", name, "value", value);

    if (name === "jointApplication" && value === 0) {
      if (
        applicationData.applicant2Details ||
        applicationData.applicant2ContactDetails ||
        applicationData.applicant2CurrentAddress ||
        applicationData.applicant2Employment
      ) {
        setApplicationData({ jointApplication: 1 });

        const handleConfirm = () => {
          setApplicationData({
            applicant2Details: null,
            applicant2ContactDetails: null,
            applicant2CurrentAddress: null,
            applicant2Employment: null,
          });
          setModal({ modalOpen: false });
          setReloadForm(true);
        };

        const handleCancel = () => {
          setModal({ modalOpen: false });
          setReloadForm(true);
        };

        const alertText = "jointApplication Warning text";
        setModal({
          modalOpen: true,
          modalOpenedFrom: "application form",
          modalType: "alert",
          modalTitle: "Alert",
          modalContent: modalAlertContent(
            alertText,
            handleConfirm,
            handleCancel
          ),
        });
      }
    }

    if (name === "propertyRegion" && value !== "10") {
      if (
        has(applicationData.mortgageDetails, "firstHomesGreaterLondon") &&
        applicationData.mortgageDetails.firstHomesGreaterLondon !== null
      ) {
        setApplicationData({ propertyRegion: "10" });

        const handleConfirm = () => {
          setApplicationData({
            mortgageDetails: {
              ...applicationData.mortgageDetails,
              firstHomesGreaterLondon: null,
            },
          });
          setModal({ modalOpen: false });
        };

        const handleCancel = () => {
          setModal({ modalOpen: false });
        };

        const alertText = "propertyRegion Warning text";
        setModal({
          modalOpen: true,
          modalOpenedFrom: "application form",
          modalType: "alert",
          modalTitle: "Alert",
          modalContent: modalAlertContent(
            alertText,
            handleConfirm,
            handleCancel
          ),
        });
      }
    }

    if (name === "firstTimeBuyer") {
      if (
        (value === 1 && applicationData.currentPropertyDetails) ||
        (value === 0 &&
          has(applicationData.mortgageDetails, "firstHomesGreaterLondon") &&
          applicationData.mortgageDetails.firstHomesGreaterLondon !== null)
      ) {
        // const options = [...defaultSchemeOptions];
        setSchemeOptions(schemeOptions);
        setApplicationData({ firstTimeBuyer: value ? 0 : 1 });

        const handleConfirm = () => {
          value &&
            setApplicationData({
              currentPropertyDetails: null,
              propertyToSell: null,
            });

          !value &&
            setApplicationData({
              schemes: null,
              mortgageDetails: {
                ...applicationData.mortgageDetails,
                firstHomesGreaterLondon: null,
              },
            });

          const options = [...defaultSchemeOptions];
          remove(options, (item) => {
            return item.value !== "sharedOwnership";
          });
          setSchemeOptions(options);

          setModal({ modalOpen: false });
          setReloadForm(true);
        };

        const handleCancel = () => {
          setModal({ modalOpen: false });
          setReloadForm(true);
        };

        const alertText = "firstTimeBuyer Warning text";
        setModal({
          modalOpen: true,
          modalOpenedFrom: "application form",
          modalType: "alert",
          modalTitle: "Alert",
          modalContent: modalAlertContent(
            alertText,
            handleConfirm,
            handleCancel
          ),
        });
      }
    }

    if (
      name === "scheme" &&
      value === 0 &&
      applicationData.schemes !== "helpToBuy"
    ) {
      if (
        (has(applicationData.mortgageDetails, "sharedOwnershipPercentage") &&
          applicationData.mortgageDetails.sharedOwnershipPercentage !== 40) ||
        (has(applicationData.mortgageDetails, "firstHomesGreaterLondon") &&
          applicationData.mortgageDetails.firstHomesGreaterLondon !== null)
      ) {
        setApplicationData({ scheme: 1 });

        const handleConfirm = () => {
          setApplicationData({
            schemes: null,
            mortgageDetails: {
              ...applicationData.mortgageDetails,
              sharedOwnershipPercentage: 40,
              firstHomesGreaterLondon: null,
            },
          });
          setModal({ modalOpen: false });
          setReloadForm(true);
        };

        const handleCancel = () => {
          setModal({ modalOpen: false });
          setReloadForm(true);
        };

        const alertText = "scheme Warning text";
        setModal({
          modalOpen: true,
          modalOpenedFrom: "application form",
          modalType: "alert",
          modalTitle: "Alert",
          modalContent: modalAlertContent(
            alertText,
            handleConfirm,
            handleCancel
          ),
        });
      }
    }

    if (name === "schemes" && value !== "helpToBuy") {
      if (
        (has(applicationData.mortgageDetails, "sharedOwnershipPercentage") &&
          applicationData.mortgageDetails.sharedOwnershipPercentage !== 40) ||
        (has(applicationData.mortgageDetails, "firstHomesGreaterLondon") &&
          applicationData.mortgageDetails.firstHomesGreaterLondon !== null)
      ) {
        setApplicationData({
          schemes:
            value === "sharedOwnership" ? "firstHomes" : "sharedOwnership",
        });

        const handleConfirm = () => {
          setApplicationData({
            mortgageDetails: {
              ...applicationData.mortgageDetails,
              sharedOwnershipPercentage: 40,
              firstHomesGreaterLondon: null,
            },
          });
          setModal({ modalOpen: false });
          setReloadForm(true);
        };

        const handleCancel = () => {
          setModal({ modalOpen: false });
          setReloadForm(true);
        };

        const alertText = "schemes Warning text";
        setModal({
          modalOpen: true,
          modalOpenedFrom: "application form",
          modalType: "alert",
          modalTitle: "Alert",
          modalContent: modalAlertContent(
            alertText,
            handleConfirm,
            handleCancel
          ),
        });
      }
    }

    if (name === "propertyToSell" && value === 0) {
      if (applicationData.currentPropertyDetails) {
        setApplicationData({ propertyToSell: 1 });

        const handleConfirm = () => {
          setApplicationData({
            currentPropertyDetails: null,
          });
          setModal({ modalOpen: false });
          setReloadForm(true);
        };

        const handleCancel = () => {
          setModal({ modalOpen: false });
          setReloadForm(true);
        };

        const alertText = "schemes Warning text";
        setModal({
          modalOpen: true,
          modalOpenedFrom: "application form",
          modalType: "alert",
          modalTitle: "Alert",
          modalContent: modalAlertContent(
            alertText,
            handleConfirm,
            handleCancel
          ),
        });
      }
    }
  }, [formDataChange]);

  const modalAlertContent = (alertText, handleConfirm, handleCancel) => {
    return (
      <div className="rounded-lg bg-white p-8 text-center text-black">
        <p className="text-md">{alertText}</p>

        <div className="flex items-center">
          <span className="mr-4">
            <ButtonDefault
              color="emerald"
              label="OK"
              onClick={() => handleConfirm()}
            />
          </span>

          <ButtonDefault
            color="white"
            label="Cancel"
            onClick={() => handleCancel()}
          />
        </div>
      </div>
    );
  };

  const schemaObj = {
    mortgageType: Joi.number().required(),
    jointApplication: Joi.number().required(),
    propertyRegion: Joi.number().required().invalid(0).messages({
      "any.invalid": "Please select a region",
    }),
    firstTimeBuyer: Joi.number().required(),
    scheme: Joi.number().required(),
    schemes: Joi.string().required(),
    propertyToSell: Joi.number().required(),
  };

  const optionsTooltip = [
    {
      value: "sharedOwnership",
      content: (
        <p className="text-md text-white">
          Shared Ownership gives those that do not currently own a home the
          opportunity to purchase a share in a new build property.
          <br />
          <a
            type="button"
            href="https://www.gov.uk/shared-ownership-scheme"
            target="_blank"
            className="text-indigo-500 hover:underline hover:underline-offset-2"
          >
            Shared ownership info
          </a>
        </p>
      ),
    },
    {
      value: "firstHomes",
      content: (
        <p className="text-md text-white">
          First Homes is a new scheme designed to help local first time buyers
          onto the property ladder. Only available for new build homes, please
          check with your local development.
          <br />
          <a
            type="button"
            href="https://www.ownyourhome.gov.uk/scheme/first-homes/"
            target="_blank"
            className="text-indigo-500 hover:underline hover:underline-offset-2"
          >
            First Homes scheme information
          </a>
        </p>
      ),
    },
    {
      value: "helpToBuy",
      content: (
        <p className="text-md text-white">
          You can get an equity loan towards the cost of buying a new build home
          as a first time buyer.
          <br />
          <a
            type="button"
            href="https://www.gov.uk/help-to-buy-equity-loan"
            target="_blank"
            className="text-indigo-500 hover:underline hover:underline-offset-2"
          >
            Help to Buy scheme information
          </a>
        </p>
      ),
    },
  ];

  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value,
    };
    doAfterChange(input);
  };

  const handleRadioChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: parseInt(currentTarget.value),
    };
    doAfterChange(input);
  };

  const doAfterChange = (input) => {
    setApplicationData({ ...applicationData, [input.name]: input.value });
    setFormDataChange(input);
  };

  return (
    <>
      {!reloadForm ? (
        <>
          <div className="mb-8">
            <RadioGroup
              name="mortgageType"
              label="Are you:"
              options={[
                { _id: 0, value: 0, option: "Buying a home" },
                { _id: 1, value: 1, option: "Remortgaging" },
              ]}
              layout="col"
              toolTip={null}
              value={applicationData.mortgageType}
              onChange={handleRadioChange}
              //error={errors.mortgageType}
            />
          </div>

          <div className="mb-8">
            <RadioGroup
              name="jointApplication"
              label="Is this a single or joint purchase?"
              options={[
                { _id: 0, value: 0, option: "Single" },
                { _id: 1, value: 1, option: "Joint" },
              ]}
              toolTip={null}
              value={applicationData.jointApplication}
              onChange={handleRadioChange}
              //error={errors.jointApplication}
            />
          </div>
          <div className="mb-8">
            <SelectMenu
              name="propertyRegion"
              label="Where is the property?"
              options={propertyRegionOptions}
              hiddenOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
              db={true}
              help={null}
              value={applicationData.propertyRegion}
              onChange={handleChange}
              error={errors.propertyRegion}
            />
          </div>
          <div className="mb-8">
            <RadioGroup
              name="firstTimeBuyer"
              label="Are you a first time buyer?"
              options={[
                { _id: 0, value: 1, option: "Yes" },
                { _id: 1, value: 0, option: "No" },
              ]}
              toolTip={null}
              value={applicationData.firstTimeBuyer}
              onChange={handleRadioChange}
              //error={errors.firstTimeBuyer}
            />
          </div>

          <div className="mb-8">
            <RadioGroup
              name="scheme"
              label={
                <span>
                  Will you be using an affordable home ownership scheme?
                  <br />
                  <span className="text-sm">
                    (For example, Shared Ownership
                    {applicationData.firstTimeBuyer ? ", First Homes" : ""}
                    {applicationData.firstTimeBuyer &&
                    applicationData.propertyRegion === "11"
                      ? ", Help to Buy."
                      : "."}
                    )
                  </span>
                </span>
              }
              options={[
                { _id: 0, value: 1, option: "Yes" },
                { _id: 1, value: 0, option: "No" },
              ]}
              toolTip={null}
              value={applicationData.scheme}
              onChange={handleRadioChange}
              //error={errors["scheme"]}
            />
          </div>

          {applicationData.scheme === 1 ? (
            <div className="mb-8">
              <RadioGroup
                name="schemes"
                label="Which scheme will you be using?"
                options={schemeOptions}
                layout="col"
                toolTip={null}
                value={applicationData.schemes}
                onChange={handleChange}
                //error={errors["schemes"]}
              />
            </div>
          ) : null}

          {applicationData.firstTimeBuyer === 0 ? (
            <div className="mb-8">
              <RadioGroup
                name="propertyToSell"
                label="Do you have a property to sell?"
                options={[
                  { _id: 0, value: 1, option: "Yes" },
                  { _id: 1, value: 0, option: "No" },
                ]}
                toolTip={null}
                value={applicationData.propertyToSell}
                onChange={handleRadioChange}
                // error={errors.propertyToSell}
              />
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default FormApplication;
