import { useContext, useEffect, useState } from "react";
import { has } from "lodash";
import SelectMenu from "../form/SelectMenu";
import InputPriceField from "../form/InputPriceField";
import RadioGroup from "../form/RadioGroup";
import { ButtonDefault } from "../Buttons";
import ApplicationPreview from "../ApplicationPreview";
import AppContext from "../../Context/AppContext";
import application from "../../data/application.json";

const CurrentPropertyDetailsForm = () => {
  const {
    applicationData,
    setApplicationData,
    titleOptions,
    modal,
    setModal,
    formData,
    showResults,
  } = useContext(AppContext);

  const [data, setData] = useState({});
  const [formDataChange, setFormDataChange] = useState({});
  // const [errors, setErrors] = useState({});

  useEffect(() => {
    const { currentPropertyDetails } = { ...applicationData };

    const propertyToSellValue =
      has(currentPropertyDetails, "propertyToSellValue") &&
      currentPropertyDetails.propertyToSellValue !== null
        ? currentPropertyDetails.propertyToSellValue
        : showResults && has(formData, "propertyToSellValue")
        ? formData.propertyToSellValue
        : null;

    const outstandingMortgageAmount =
      has(currentPropertyDetails, "outstandingMortgageAmount") &&
      currentPropertyDetails.outstandingMortgageAmount
        ? currentPropertyDetails.outstandingMortgageAmount
        : showResults && has(formData, "outstandingMortgageAmount")
        ? formData.outstandingMortgageAmount
        : null;

    setData({
      propertyToSellValue,
      outstandingMortgageAmount,
    });
  }, []);

  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value,
    };
    doAfterChange(input);
  };

  const handlePriceChange = ({ currentTarget }) => {
    const input = {
      value: Number(currentTarget.value.replace(/[^0-9.-]+/g, "")),
      name: currentTarget.name,
    };
    doAfterChange(input);
  };

  const handleRadioChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: parseInt(currentTarget.value),
    };
    doAfterChange(input);
  };

  const doAfterChange = (input) => {
    setData({
      ...data,
      [input.name]: input.value,
    });
    setFormDataChange(input);
  };

  const handleSave = () => {
    handleModalClose();

    setApplicationData({
      ...applicationData,
      currentPropertyDetails: data,
    });
  };

  const handleModalClose = () => {
    modal.modalOpenedFrom === "preview"
      ? setModal({
          modalOpen: true,
          modalType: "preview",
          modalTitle: "Preview application",
          modalContent: <ApplicationPreview />,
        })
      : setModal({ modalOpen: false });
  };

  const { formFields } =
    application.cards.mortgage.forms.currentPropertyDetails;

  return (
    <div className="rounded-lg bg-white p-8 text-center text-black">
      <p className="mb-4 text-4xl">{modal.modalTitle}</p>

      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm">
          <InputPriceField
            name="propertyToSellValue"
            label={formFields.propertyToSellValue.label}
            type="text"
            help={null}
            value={data.propertyToSellValue}
            onChange={handlePriceChange}
            //   error={errors["propertyToSellValue"]}
          />
        </div>
      </div>

      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm">
          <InputPriceField
            name="outstandingMortgageAmount"
            label={formFields.outstandingMortgageAmount.label}
            type="text"
            help={null}
            value={data.outstandingMortgageAmount}
            onChange={handlePriceChange}
            //   error={errors["outstandingMortgageAmount"]}
          />
        </div>
      </div>

      <div className="flex justify-center pt-4">
        <div className="w-full max-w-sm ">
          <div className="flex items-center">
            <span className="mr-4">
              <ButtonDefault
                color="emerald"
                label="Save"
                onClick={handleSave}
              />
            </span>

            <ButtonDefault
              color="white"
              label="Cancel"
              onClick={handleModalClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentPropertyDetailsForm;
