import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { has, isEmpty, merge, take, uniq } from "lodash";

import Title from "../components/Title";
import ProductCard from "../components/ProductCard";
import FormContact from "../components/FormContact";

import {
  isToday,
  renderDate,
  renderDay,
  renderTime,
} from "../utils/renderAppointmentDetails";
import removeScrollLockClasses from "../utils/removeScrollLockClasses";
import AppContext from "../Context/AppContext";

function ContactPage(props) {
  let navigate = useNavigate();
  let location = useLocation();

  const {
    formData,
    setFormData,
    // appointment,
    // setAppointment,
    referral,
    contactFormSubmitted,
    setContactFormSubmitted,
  } = useContext(AppContext);

  const [productData, setProductData] = useState({});
  //const [appointmentData, setAppointmentData] = useState({});
  const [appointment, setAppointment] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (location.state) {
      const product = location.state.product;
      product.infoButton = false;

      setProductData(product);
    }

    removeScrollLockClasses();
  }, []);

  // useEffect(() => {
  //   appointment && setAppointmentData(formData.appointment);
  // }, [appointment]);

  const handleProductInfoButton = () => {
    const product = { ...productData };
    product.infoButton = product.infoButton ? false : true;

    setProductData(product);
  };

  const handleRemoveProduct = () => {
    setProductData({});
  };

  const getSuccessMessage = () => {
    const appointmentMessage =
      appointment.time !== "" ? (
        <p className="text-lg">
          We have booked you an appointment for:
          <br />
          <span className="font-bold">{appointment.text}</span>
          <br />
          An adviser will call you by phone to discuss your requirements.
        </p>
      ) : (
        <p className="text-lg">
          An adviser will call you by phone on the number you have provided as{" "}
          <span className="font-bold">soon as possible</span> to discuss your
          requirements.
        </p>
      );

    return appointmentMessage;
  };

  return (
    <>
      <div id="contact" className="scroll-mt-32">
        <div className="mx-auto mb-16 mt-12 w-full max-w-4xl px-4 md:px-8">
          <div className="mb-8">
            <Title title="Get in touch" stage="3" />
          </div>

          <div className="frost rounded-lg border border-gray-200 bg-white/50 p-4  shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black/50 dark:shadow-purple-500/50">
            {!contactFormSubmitted && (
              <>
                {/* <p className="text-md mb-4 text-center text-black dark:text-white">
                Fill and submit the form below to arrange a date and time for a
                qualified adviser to call you.
              </p> */}

                {!isEmpty(productData) ? (
                  <div className="mb-16">
                    <ProductCard
                      product={productData}
                      getLenderLogo={null}
                      handleInfoButton={handleProductInfoButton}
                      handleRemoveProduct={handleRemoveProduct}
                    />
                  </div>
                ) : null}

                <FormContact
                  // formData={formData}
                  // setFormData={setFormData}
                  product={productData}
                  appointment={appointment}
                  setAppointment={setAppointment}
                  // setContactFormSubmitted={setContactFormSubmitted}
                />
              </>
            )}
            {contactFormSubmitted && (
              <div className="frost m-8 rounded-lg bg-white/50 p-8 text-center text-black shadow-xl  shadow-purple-500/25 dark:bg-black/50 dark:text-white dark:shadow-purple-500/50">
                <p className="mb-4 text-2xl font-bold">
                  Thank you for your interest in Threshold Mortgages
                </p>
                {getSuccessMessage()}
                {/* <p>//////////////////</p>
                <p className="text-md">Your appointment has been booked for:</p>

                <p className="mb-4 text-lg font-bold">
                  {appointment.text} */}
                {/* <span className="font-bold">
                    {renderDay(appointment.dayToCall)}
                  </span>
                  {renderTime(appointment.timeToCall) && ","}{" "}
                  <span className="font-bold">
                    {renderTime(appointment.timeToCall)}
                  </span> */}
                {/* <p className="mb-4">({renderDate(appointment.dayToCall)})</p> */}{" "}
                {/* </p>

                <p className="text-md mb-4">
                  An adviser will call you by phone on the number you have
                  provided to discuss your requirements
                </p> */}
                {/* <button
                type="button"
                className="ml-4 inline-flex items-center rounded-md border border-gray-400 bg-white px-4 py-2 text-sm text-black shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-black dark:text-white dark:hover:bg-gray-900"
                onClick={() => navigate("/")}
              >
                Return
              </button> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactPage;
