const ApplicationPreviewListItem = ({ itemName, itemValue }) => {
  return (
    <li className="flex justify-between border-b border-gray-200 p-2">
      <span>{itemName}</span>

      <span className="font-bold">{itemValue} </span>
    </li>
  );
};

export default ApplicationPreviewListItem;
