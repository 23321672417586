import React, { Fragment } from "react";
import Tooltip from "../Tooltip";

function OtherFees(props) {
  return (
    <div className="mb-8 rounded-lg border border-gray-200 bg-white p-4 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black dark:shadow-purple-500/50">
      {/* <div className="bg-gradient-to-tl from-rose-500  via-pink-500 to-fuchsia-500 shadow-md shadow-purple-500/25 dark:shadow-purple-500/50 rounded-lg p-4 mb-8"> */}
      <h2 className="mb-4 text-3xl leading-none text-black dark:text-white">
        Don't forget!
      </h2>
      {/* <div className="border border-white/50 bg-black/10 rounded-lg mb-4 p-2"> */}
      <Fragment>
        <div className="mb-4 flex items-start justify-between border-b-2 border-gray-200 pb-2 dark:border-gray-800">
          <div className="text-black dark:text-white">
            <span className="text-lg">Surveys</span>
            <br />
            <span className="text-xl font-bold">£300-£900</span>
            <br />
            <span className="text-xs">One off transactional fee</span>
          </div>
          <Tooltip
            content={
              <p className="text-md text-white">
                A property survey is an expert inspection of a property's
                condition, which identifies problems to a prospective buyer.
              </p>
            }
          />
        </div>
        <div className="mb-0 flex items-start justify-between border-b-2 border-gray-200 pb-2 dark:border-gray-800">
          <div className="text-black dark:text-white">
            <span className="text-lg">Legal fees</span>
            <br />
            <span className="text-xl font-bold">£850-£1,500</span>
            <br />
            <span className="text-xs">One off transactional fee</span>
          </div>
          <Tooltip
            content={
              <p className="text-md text-white">
                Fees associated with buying or selling a house, such as property
                searches and Land Registry registration.
              </p>
            }
          />
        </div>
      </Fragment>
    </div>
    // </div>
  );
}

export default OtherFees;
