import { useContext } from "react";
import { ButtonSmall } from "./Buttons";
import AppContext from "../Context/AppContext";

const ApplicationFormButton = ({ label, formTitle, form, placement }) => {
  const { setModal } = useContext(AppContext);

  return (
    <ButtonSmall
      color="plain"
      label={label}
      onClick={() =>
        setModal({
          modalOpen: true,
          modalOpenedFrom: placement,
          modalType: "form",
          modalTitle: formTitle,
          modalContent: form,
        })
      }
    />
  );
};

export default ApplicationFormButton;
