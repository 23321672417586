// import { useContext } from "react";
// import { has } from "lodash";
import ApplicationFormButton from "./ApplicationFormButton";

// import AppContext from "../Context/AppContext";
import ApplicationPreviewListItem from "./ApplicationPreviewListItem";

const ApplicationPreviewList = ({ title, form, listItems }) => {
  // const { applicationData } = useContext(AppContext);

  return (
    <>
      <div className="flex items-center justify-between">
        <p className="mb-2 text-left text-xl font-bold">{title}</p>
        <ApplicationFormButton
          label="Edit"
          formTitle={title}
          form={form}
          placement="preview"
        />
      </div>
      <ul className="mb-8 rounded-lg border border-b-0 border-gray-200 text-lg">
        {listItems.map((item) => (
          <ApplicationPreviewListItem
            key={item.itemName}
            itemName={item.itemName}
            itemValue={item.itemValue}
          />
        ))}
      </ul>
    </>
  );
};

export default ApplicationPreviewList;
