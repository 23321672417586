import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { has } from "lodash";
import PropertyPurchasePrice from "./widgets/PropertyPurchasePrice";
import DepositChecker from "./widgets/DepositChecker";
import AffordabilityCalculator from "./widgets/AffordabilityCalculator";
import MortgageCalculator from "./widgets/MortgageCalculator";
import SharedOwnershipResults from "./widgets/SharedOwnershipResults";
import StampDutyCalculator from "./widgets/StampDutyCalculator";
import OtherFees from "./widgets/OtherFees";
import FindMyMortgage from "./widgets/FindMyMortgage";
import GetInTouch from "./widgets/GetInTouch";

import AppContext from "../Context/AppContext";
import { getMinDepositAmount } from "../utils/getSharedOwnershipDepositAlert";

import { calculateAffordability } from "../utils/calculators";
import settings from "../data/settings.json";
import CallToActions from "./CallToActions";

function Results(props) {
  const {
    formData,
    setFormData,
    referral,
    calculatedPropertyPurchasePrice,
    setCalculatedPropertyPurchasePrice,
    calculatedLaonAmount,
    setCalculatedLaonAmount,
    formComplete,
    setFormComplete,
    showResults,
    setShowResults,
  } = useContext(AppContext);
  // const [calculatedLaonAmount, setCalculatedLaonAmount] = useState(null);
  const [propertyMarketValue, setPropertyMarketValue] = useState(null);
  const [propertyRegionDetails, setPropertyRegionDetails] = useState({});
  const [firstHomesQualify, setFirstHomesQualify] = useState(false);
  const [helpToBuyShare, setHelpToBuyShare] = useState(null);

  // useEffect(() => {
  //   setCalculatedSharedOwnershipLoanAmount(
  //     formData.sharePrice - formData.deposit
  //   );
  //   setCalculatedSharedOwnershipRentalAmount(
  //     calculatedPropertyPurchasePrice - formData.sharePrice
  //     // 300000 - formData.sharePrice
  //   );

  //   // set
  // }, [formData, calculatedPropertyPurchasePrice]);

  // deposit fix
  useEffect(() => {
    const propertyRegion = null;

    setPropertyRegionDetails(propertyRegion);

    const results = calculateAffordability(
      formData.income,
      formData.deposit,
      formData.buyToLet,
      formData.propertyPurchasePrice,
      formData.sharedOwnershipPercentage
    );

    // loan amount
    // const loanAmount = formData.firstHomes
    const loanAmount =
      formData.schemes === "sharedOwnership"
        ? results[6]
        : formData.schemes === "firstHomes"
        ? firstHomesQualify
          ? results[2] - formData.deposit
          : getFirstHomesPriceCap() - formData.deposit
        : formData.schemes === "helpToBuy"
        ? results[3]
        : results[0];

    //console.log("recalc here!!", loanAmount, results[6]);

    // property purchase price
    let price =
      results[
        //  formData.firstHomes
        formData.schemes === "firstHomes"
          ? firstHomesCheck(results[2])
            ? 2
            : 1
          : formData.schemes === "helpToBuy"
          ? 5
          : 1
      ];

    //    if (formData.firstHomes) {
    if (formData.schemes === "firstHomes") {
      setFirstHomesQualify(firstHomesCheck(price));
      price = firstHomesCheck(price) ? price : getFirstHomesPriceCap();
    }

    const calculateResults = () => {
      //   formData.schemes !== "sharedOwnership" &&
      setCalculatedLaonAmount(loanAmount);
      setCalculatedPropertyPurchasePrice(price);
      setPropertyMarketValue(price / 0.7);
      setHelpToBuyShare(results[4]);
      setFormComplete(true);
      //  setShowResults(true);
    };
    const resetResults = () => {
      setCalculatedLaonAmount(null);
      setCalculatedPropertyPurchasePrice(null);
      setPropertyMarketValue(null);
      setHelpToBuyShare(null);
      setFormComplete(false);
      //   setShowResults(false);
    };

    if (
      has(formData, "jointApplication") &&
      formData.propertyRegion &&
      formData.propertyRegion !== "0" &&
      has(formData, "firstTimeBuyer") &&
      has(formData, "scheme") &&
      formData.scheme !== null &&
      formData.deposit &&
      formData.income
    ) {
      if (formData.firstTimeBuyer) {
        resetResults();

        if (formData.scheme === 1) {
          resetResults();

          if (
            formData.schemes === "sharedOwnership" &&
            formData.sharedOwnershipPercentage &&
            formData.propertyPurchasePrice &&
            formData.deposit >=
              getMinDepositAmount(
                (formData.propertyPurchasePrice *
                  formData.sharedOwnershipPercentage) /
                  100
              )
          ) {
            calculateResults();
            //console.log("TEST8");
          }

          if (
            formData.schemes === "firstHomes" &&
            has(formData, "firstHomesGreaterLondon") &&
            formData.firstHomesGreaterLondon !== null
          ) {
            calculateResults();
            //console.log("TEST7");
          }

          if (formData.schemes === "helpToBuy") {
            calculateResults();
            // console.log("TEST6");
          }
        } else {
          resetResults();

          if (has(formData, "buyToLet") && formData.buyToLet !== null) {
            calculateResults();
            //   console.log("TEST5");
          }
        }
      } else {
        resetResults();

        if (formData.scheme === 1) {
          resetResults();

          if (
            formData.schemes === "sharedOwnership" &&
            formData.sharedOwnershipPercentage &&
            has(formData, "propertyToSell") &&
            formData.propertyToSell !== null &&
            formData.propertyPurchasePrice &&
            formData.deposit >=
              getMinDepositAmount(
                (formData.propertyPurchasePrice *
                  formData.sharedOwnershipPercentage) /
                  100
              )
          ) {
            resetResults();

            if (formData.propertyToSell === 1) {
              resetResults();

              if (
                formData.propertyToSellValue &&
                formData.outstandingMortgageAmount !== null
              ) {
                calculateResults();
                // console.log("TEST4");
              } else {
                resetResults();
                //console.log("TEST44");
              }
            } else {
              calculateResults();
              // console.log("TEST3");
            }
          }
        } else {
          /// scheme = 0

          if (
            has(formData, "propertyToSell") &&
            formData.propertyToSell !== null &&
            has(formData, "buyToLet") &&
            formData.buyToLet !== null
          ) {
            resetResults();

            if (formData.propertyToSell === 1) {
              resetResults();

              if (
                (formData.buyToLet === 1 ||
                  (formData.buyToLet === 0 &&
                    has(formData, "additionalPropertyPurchase") &&
                    formData.additionalPropertyPurchase !== null)) &&
                formData.propertyToSellValue &&
                formData.outstandingMortgageAmount !== null
              ) {
                //   console.log("TEST2");

                calculateResults();
              }
            } else {
              resetResults();

              if (
                formData.buyToLet === 1 ||
                (formData.buyToLet === 0 &&
                  has(formData, "additionalPropertyPurchase") &&
                  formData.additionalPropertyPurchase !== null)
              ) {
                //     console.log("TEST1");
                calculateResults();
              }
            }
          } else {
          }
        }

        ////

        // if (
        //   has(formData, "propertyToSell") &&
        //   formData.propertyToSell !== null &&
        //   has(formData, "buyToLet") &&
        //   formData.buyToLet !== null
        // ) {
        //   resetResults();

        //   if (formData.propertyToSell === 1) {
        //     resetResults();

        //     if (
        //       (formData.buyToLet === 1 ||
        //         (formData.buyToLet === 0 &&
        //           has(formData, "additionalPropertyPurchase") &&
        //           formData.additionalPropertyPurchase !== null)) &&
        //       formData.propertyToSellValue &&
        //       formData.outstandingMortgageAmount
        //     ) {
        //       calculateResults();
        //     }
        //     // else {
        //     //   resetResults();
        //     // }
        //   } else {
        //     resetResults();

        //     if (
        //       formData.buyToLet === 1 ||
        //       (formData.buyToLet === 0 &&
        //         has(formData, "additionalPropertyPurchase") &&
        //         formData.additionalPropertyPurchase !== null)
        //     ) {
        //       calculateResults();
        //     }
        //   }
        // }
      }
    } else {
      resetResults();
    }
  }, [formData, firstHomesQualify]);

  useEffect(() => {
    !formComplete && setShowResults(false);
  }, [formComplete]);

  // const firstHomesCheck = (price) => {
  //   const result = formData.firstHomesGreaterLondon
  //     ? price <= settings.firstHomes.priceCapLondon
  //       ? true
  //       : false
  //     : price <= settings.firstHomes.priceCap
  //     ? true
  //     : false;

  //   return result;
  // };

  const firstHomesCheck = (price) => {
    return price <= getFirstHomesPriceCap() ? true : false;
  };

  const getFirstHomesPriceCap = () => {
    return formData.firstHomesGreaterLondon
      ? settings.firstHomes.priceCapLondon
      : settings.firstHomes.priceCap;
  };

  // const handleSetCalculatedPropertyPurchasePrice = (price) => {
  //   setCalculatedPropertyPurchasePrice(price);
  // };

  return (
    <>
      <div
        className={`transition-opacity duration-500 ${
          showResults && formComplete ? "opacity-100" : "opacity-25"
        }`}
      >
        <div className="mb-8 rounded-lg border border-gray-200 bg-white p-4 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black dark:shadow-purple-500/50">
          <div className="mb-4">
            <PropertyPurchasePrice
              formData={formData}
              calculatedPropertyPurchasePrice={calculatedPropertyPurchasePrice}
              // setCalculatedPropertyPurchasePrice={
              //   handleSetCalculatedPropertyPurchasePrice
              // }
              propertyMarketValue={propertyMarketValue}
              firstHomesQualify={firstHomesQualify}
              // propertyRegionDetails={propertyRegionDetails}
            />
          </div>
          <AffordabilityCalculator
            formData={formData}
            calculatedLaonAmount={calculatedLaonAmount}
            calculatedPropertyPurchasePrice={calculatedPropertyPurchasePrice}
            // propertyMarketValue={propertyMarketValue}
            firstHomesQualify={firstHomesQualify}
            // propertyRegionDetails={propertyRegionDetails}
            helpToBuyShare={helpToBuyShare}
          />

          {/* {showResults && (
            <div
              className={`mt-4 transition-opacity duration-500 ${
                showResults ? "opacity-100" : "opacity-25"
              }`}
            >
              <CallToActions />
            </div>
          )} */}
        </div>

        <DepositChecker
          formData={formData}
          calculatedPropertyPurchasePrice={calculatedPropertyPurchasePrice}
          propertyRegionDetails={propertyRegionDetails}
        />

        <SharedOwnershipResults
          formData={formData}
          setFormData={setFormData}
          calculatedLaonAmount={calculatedLaonAmount}
          setCalculatedLaonAmount={setCalculatedLaonAmount}
          // calculatedPropertyPurchasePrice={calculatedPropertyPurchasePrice}
        />

        <MortgageCalculator
          formData={formData}
          setFormData={setFormData}
          calculatedLaonAmount={calculatedLaonAmount}
          calculatedPropertyPurchasePrice={calculatedPropertyPurchasePrice}
        />
        <StampDutyCalculator
          formData={formData}
          calculatedPropertyPurchasePrice={calculatedPropertyPurchasePrice}
        />

        <OtherFees />

        {/* {calculatedPropertyPurchasePrice || calculatedLaonAmount ? (
          <div className="mb-8 rounded-lg border border-gray-200 bg-white p-4 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black dark:shadow-purple-500/50">
            <div className="mb-0 rounded-lg border border-gray-400 bg-gradient-to-tl from-gray-100 p-2 dark:border-gray-600 dark:bg-gray-900 dark:from-gray-900">
              <CallToActions animate={true} />
            </div>
          </div>
        ) : null} */}

        <div className="">
          <FindMyMortgage />

          <GetInTouch />
        </div>

        <p className="mb-4 text-center text-sm text-black dark:text-white">
          The above results are based on the{" "}
          {formData.buyToLet ? "deposit" : "income"} figure you've provided.
          Please be aware that{" "}
          {formData.buyToLet
            ? "rental income and financial commitments will need to be"
            : "credit commitments and credit ratings have not been"}{" "}
          taken into consideration.
        </p>

        <p className="mb-4 text-center text-sm text-black dark:text-white">
          For an initial fee free mortgage review and affordability check,{" "}
          <Link
            to="/contact"
            className="font-bold text-emerald-500 hover:underline"
            //onClick={() => setOpenResultsPanel(false)}
          >
            get in touch
          </Link>{" "}
          with one of our qualified mortgage advisers. Alternatively call{" "}
          <a
            href={`tel:${referral ? "03458945522" : "03300249115"}`}
            className="font-bold text-emerald-500  hover:underline"
          >
            {referral ? (
              <span>03458&nbsp;945&nbsp;522</span>
            ) : (
              <span>03300&nbsp;249&nbsp;115</span>
            )}
          </a>
          .
        </p>

        <div className="mb-4 border border-black p-2 dark:border-white">
          <p className="text-center uppercase text-black dark:text-white">
            Your property may be repossessed if you do not keep up repayments on
            your mortgage
          </p>
        </div>

        <p className="mb-4 text-center text-sm text-black dark:text-white">
          Some buy to let mortgages are not regulated by the Financial Conduct
          Authority
        </p>

        <p className="mb-2 text-xs text-black dark:text-white">
          Threshold Financial Services Limited is an appointed representative of
          The Openwork Partnership, a trading style of Openwork Limited which is
          authorised and regulated by the Financial Conduct Authority
        </p>

        <p className="text-xs text-black dark:text-white">
          Approved by The Openwork Partnership on 10/08/2023
        </p>
      </div>
    </>
  );
}

export default Results;
