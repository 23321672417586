import { useContext } from "react";
import { has } from "lodash";
import ApplicationCardItem from "./ApplicationCardItem";
import Applicant1DetailsForm from "./application-forms/Applicant1DetailsForm";
import Applicant2DetailsForm from "./application-forms/Applicant2DetailsForm";
import Applicant1ContactDetailsForm from "./application-forms/Applicant1ContactDetailsForm";
import Applicant2ContactDetailsForm from "./application-forms/Applicant2ContactDetailsForm";
import Applicant1CurrentAddressForm from "./application-forms/Applicant1CurrentAddressForm";
import Applicant2CurrentAddressForm from "./application-forms/Applicant2CurrentAddressForm";
import ApplicantsDependentsForm from "./application-forms/ApplicantsDependentsForm";
import capitalizeEachWord from "../utils/capitalizeEachWord";
import application from "../data/application.json";
import AppContext from "../Context/AppContext";

const DetailsCardItems = () => {
  const { applicationData } = useContext(AppContext);

  const { forms } = application.cards.details;

  return (
    <>
      <ApplicationCardItem
        formTitle={forms.applicant1Details.formTitle}
        form={<Applicant1DetailsForm />}
      />

      {applicationData.jointApplication ? (
        <ApplicationCardItem
          formTitle={`${
            has(applicationData.applicant2Details, "firstName") &&
            applicationData.applicant2Details.firstName !== ""
              ? capitalizeEachWord(applicationData.applicant2Details.firstName)
              : "Other applicant"
          }'s details`}
          form={<Applicant2DetailsForm />}
        />
      ) : null}

      <ApplicationCardItem
        formTitle={forms.applicant1ContactDetails.formTitle}
        form={<Applicant1ContactDetailsForm />}
      />

      {applicationData.jointApplication ? (
        <ApplicationCardItem
          formTitle={`${
            has(applicationData.applicant2Details, "firstName") &&
            applicationData.applicant2Details.firstName !== ""
              ? capitalizeEachWord(applicationData.applicant2Details.firstName)
              : "Other applicant"
          }'s contact details`}
          form={<Applicant2ContactDetailsForm />}
        />
      ) : null}

      <ApplicationCardItem
        formTitle={forms.applicant1CurrentAddress.formTitle}
        form={<Applicant1CurrentAddressForm />}
      />

      {applicationData.jointApplication ? (
        <ApplicationCardItem
          formTitle={`${
            has(applicationData.applicant2Details, "firstName") &&
            applicationData.applicant2Details.firstName !== ""
              ? capitalizeEachWord(applicationData.applicant2Details.firstName)
              : "Other applicant"
          }'s current address`}
          form={<Applicant2CurrentAddressForm />}
        />
      ) : null}

      <ApplicationCardItem
        formTitle={forms.applicantsDependents.formTitle}
        form={<ApplicantsDependentsForm />}
      />
    </>
  );
};

export default DetailsCardItems;
