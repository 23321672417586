import ApplicationFormButton from "./ApplicationFormButton";

const ApplicationCardItem = ({ formTitle, form }) => {
  return (
    <div className="mb-2 flex items-center justify-between">
      {formTitle}
      <ApplicationFormButton
        label="Complete"
        formTitle={formTitle}
        form={form}
        placement="card"
      />
    </div>
  );
};

export default ApplicationCardItem;
