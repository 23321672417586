import NumberFormat from "react-number-format";
import LabelCheckMark from "./LabelCheckMark";
import Tooltip from "../Tooltip";
import Alert from "../Alert";

function InputPriceField({
  name,
  label,
  labelCheckMark = true,
  help,
  value,
  acceptZeroValue = false,
  onChange,
  error,
  alert,
  disabled,
}) {
  const getLabelClasses = () => {
    return !disabled ? "text-black dark:text-white" : "text-gray-500";
  };

  const labelCheckMarkValue =
    (value || (value === 0 && acceptZeroValue)) && !error && !alert
      ? true
      : false;

  return (
    <div className="">
      <div className="flex items-start justify-between">
        <div className="flex items-start">
          {labelCheckMark && <LabelCheckMark value={labelCheckMarkValue} />}

          <label
            htmlFor={name}
            className={`text-md block text-left font-medium ${getLabelClasses()}`}
          >
            {label}
          </label>
        </div>
        {help && <Tooltip content={help} disabled={disabled} />}
      </div>

      <div className="mt-1">
        <NumberFormat
          //type={type}
          name={name}
          id={name}
          value={value ? value : null}
          className="text-md block w-full rounded-lg border-gray-400 bg-white font-bold text-black focus:border-indigo-500 focus:ring-indigo-500  disabled:cursor-not-allowed disabled:border-gray-200 disabled:text-gray-500 dark:border-gray-600 dark:bg-black dark:text-white disabled:dark:border-gray-800 disabled:dark:text-gray-500"
          aria-describedby={`${name}-help`}
          onChange={onChange}
          //displayType={"text"}
          thousandSeparator={true}
          prefix={"£"}
          decimalScale={2}
          //allowEmptyFormatting={true}
          disabled={disabled}
        />
      </div>
      {error && <Alert type="danger" text={error} />}
    </div>
  );
}

export default InputPriceField;
