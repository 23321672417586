import { useContext } from "react";
import { has } from "lodash";
import ApplicationPreviewList from "./ApplicationPreviewList";
import Applicant1DetailsForm from "./application-forms/Applicant1DetailsForm";
import AppContext from "../Context/AppContext";
import application from "../data/application.json";

const Applicant1PreviewList = () => {
  const { applicationData } = useContext(AppContext);

  const { applicant1Details } = applicationData;

  const { formTitle, formFields } =
    application.cards.details.forms.applicant1Details;

  return (
    <ApplicationPreviewList
      title={formTitle}
      form={<Applicant1DetailsForm />}
      listItems={[
        {
          itemName: formFields.title.label,
          itemValue: has(applicant1Details, "title")
            ? applicant1Details.title
            : null,
        },
        {
          itemName: "Name",
          itemValue: `${
            has(applicant1Details, "firstName")
              ? applicant1Details.firstName
              : ""
          } ${
            has(applicant1Details, "firstName") &&
            applicant1Details.firstName !== "" &&
            has(applicant1Details, "lastName")
              ? applicant1Details.lastName
              : ""
          }`,
        },
        {
          itemName: formFields.dateOfBirth.label,
          itemValue: has(applicant1Details, "dateOfBirth")
            ? applicationData.applicant1Details.dateOfBirth
            : null,
        },
        {
          itemName: formFields.placeOfBirth.label,
          itemValue: has(applicant1Details, "placeOfBirth")
            ? applicant1Details.placeOfBirth
            : null,
        },
        {
          itemName: formFields.nationality.label,
          itemValue: has(applicant1Details, "nationality")
            ? applicant1Details.nationality
            : null,
        },
      ]}
    />
  );
};

export default Applicant1PreviewList;
