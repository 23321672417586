import { useContext, useEffect, useState } from "react";
import { has, isEmpty } from "lodash";
import Joi from "joi";

import InputField from "../form/InputField";
import SelectMenu from "../form/SelectMenu";
import InputPriceField from "../form/InputPriceField";
import { ButtonDefault } from "../Buttons";

import { validateProperty } from "../../utils/validation";

import ApplicationPreview from "../ApplicationPreview";
import AppContext from "../../Context/AppContext";

import application from "../../data/application.json";
import RadioGroup from "../form/RadioGroup";

const Applicant1OutgoingsForm = () => {
  const { applicationData, setApplicationData, modal, setModal } =
    useContext(AppContext);

  const [data, setData] = useState({});
  const [formDataChange, setFormDataChange] = useState({});
  //const [errors, setErrors] = useState({});

  useEffect(() => {
    const { applicant1Outgoings } = { ...applicationData };

    const loans =
      has(applicant1Outgoings, "loans") && applicant1Outgoings.loans !== null
        ? applicant1Outgoings.loans
        : null;

    // const employmentStatus =
    //   has(applicant1Employment, "employmentStatus") &&
    //   applicant1Employment.employmentStatus !== null
    //     ? applicant1Employment.employmentStatus
    //     : null;

    // const currentOccupation =
    //   has(applicant1Employment, "currentOccupation") &&
    //   applicant1Employment.currentOccupation
    //     ? applicant1Employment.currentOccupation
    //     : null;

    // const companyName =
    //   has(applicant1Employment, "companyName") &&
    //   applicant1Employment.companyName
    //     ? applicant1Employment.companyName
    //     : null;

    // const currentOccupationStartDate =
    //   has(applicant1Employment, "currentOccupationStartDate") &&
    //   applicant1Employment.currentOccupationStartDate
    //     ? applicant1Employment.currentOccupationStartDate
    //     : null;

    // const income =
    //   has(applicant1Employment, "income") && applicant1Employment.income
    //     ? applicant1Employment.income
    //     : null;

    setData({
      loans,
      //   employmentStatus,
      //   currentOccupation,
      //   companyName,
      //   currentOccupationStartDate,
      //   income,
    });
  }, []);

  //   useEffect(() => {
  //     // VALIDATE PROPERTY
  //     if (!isEmpty(formDataChange))
  //       validateProperty(formDataChange, schemaObj, errors, setErrors);
  //   }, [formDataChange]);

  //   const schemaObj = {
  //     email: Joi.string().email({ tlds: false }).allow(null, "").messages({
  //       "string.email": "Please enter a valid email address",
  //     }),
  //     phone: Joi.string().allow(null, ""),
  //   };

  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value,
    };
    doAfterChange(input);
  };

  const handlePriceChange = ({ currentTarget }) => {
    const input = {
      value: Number(currentTarget.value.replace(/[^0-9.-]+/g, "")),
      name: currentTarget.name,
    };
    doAfterChange(input);
  };

  const handleRadioChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: parseInt(currentTarget.value),
    };
    doAfterChange(input);
  };

  const doAfterChange = (input) => {
    setData({
      ...data,
      [input.name]: input.value,
    });
    setFormDataChange(input);
  };

  const handleSave = () => {
    handleModalClose();
    setApplicationData({
      ...applicationData,
      applicant1Outgoings: data,
    });
  };

  const handleModalClose = () => {
    modal.modalOpenedFrom === "preview"
      ? setModal({
          modalOpen: true,
          modalType: "preview",
          modalTitle: "Preview application",
          modalContent: <ApplicationPreview />,
        })
      : setModal({ modalOpen: false });
  };

  const { formFields } = application.cards.outgoings.forms.applicant1Outgoings;

  return (
    <div className="rounded-lg bg-white p-8 text-center text-black">
      <p className="mb-4 text-4xl">{modal.modalTitle}</p>
      <div className="mb-4 flex justify-center">
        <div className="w-full max-w-sm ">
          <RadioGroup
            name="loans"
            label={formFields.loans.label}
            options={[
              { _id: 0, value: 1, option: "Yes" },
              { _id: 1, value: 0, option: "No" },
            ]}
            help={null}
            value={data.loans}
            onChange={handleRadioChange}
            //error={errors.employmentStatus}
          />
        </div>
      </div>
      {data.employmentStatus > 0 && data.employmentStatus < 5 ? (
        <>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm ">
              <InputField
                name="currentOccupation"
                label={formFields.currentOccupation.label}
                type="text"
                help={null}
                value={data.currentOccupation}
                onChange={handleChange}
                // error={errors.moveInDate}
              />
            </div>
          </div>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm ">
              <InputField
                name="companyName"
                label={formFields.companyName.label}
                type="text"
                help={null}
                value={data.companyName}
                onChange={handleChange}
                // error={errors.moveInDate}
              />
            </div>
          </div>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm ">
              <InputField
                name="currentOccupationStartDate"
                label={formFields.currentOccupationStartDate.label}
                type="text"
                help={null}
                value={data.currentOccupationStartDate}
                onChange={handleChange}
                // error={errors.moveInDate}
              />
            </div>
          </div>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm ">
              <InputPriceField
                name="income"
                label={formFields.income.label}
                type="text"
                help={null}
                value={data.income}
                onChange={handlePriceChange}
                // error={errors.moveInDate}
              />
            </div>
          </div>
        </>
      ) : null}
      <div className="flex justify-center pt-4">
        <div className="w-full max-w-sm ">
          <div className="flex items-center">
            <span className="mr-4">
              <ButtonDefault
                color="emerald"
                label="Save"
                onClick={handleSave}
              />
            </span>

            <ButtonDefault
              color="white"
              label="Cancel"
              onClick={handleModalClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applicant1OutgoingsForm;
