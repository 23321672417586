import { useContext } from "react";
import { has } from "lodash";
import ApplicationPreviewList from "./ApplicationPreviewList";
import Applicant2DetailsForm from "./application-forms/Applicant2DetailsForm";
import AppContext from "../Context/AppContext";
import application from "../data/application.json";
import capitalizeEachWord from "../utils/capitalizeEachWord";

const Applicant2PreviewList = () => {
  const { applicationData } = useContext(AppContext);

  const { applicant2Details } = applicationData;

  const { formFields } = application.cards.details.forms.applicant2Details;

  return (
    <ApplicationPreviewList
      title={`${
        has(applicationData.applicant2Details, "firstName") &&
        applicationData.applicant2Details.firstName !== ""
          ? capitalizeEachWord(applicationData.applicant2Details.firstName)
          : "Other applicant"
      }'s details`}
      form={<Applicant2DetailsForm />}
      listItems={[
        {
          itemName: formFields.title.label,
          itemValue: has(applicant2Details, "title")
            ? applicant2Details.title
            : null,
        },
        {
          itemName: "Name",
          itemValue: `${
            has(applicant2Details, "firstName")
              ? applicant2Details.firstName
              : ""
          } ${
            has(applicant2Details, "firstName") &&
            applicant2Details.firstName !== "" &&
            has(applicant2Details, "lastName")
              ? applicant2Details.lastName
              : ""
          }`,
        },
        {
          itemName: formFields.dateOfBirth.label,
          itemValue: has(applicant2Details, "dateOfBirth")
            ? applicationData.applicant2Details.dateOfBirth
            : null,
        },
        {
          itemName: formFields.placeOfBirth.label,
          itemValue: has(applicant2Details, "placeOfBirth")
            ? applicant2Details.placeOfBirth
            : null,
        },
        {
          itemName: formFields.nationality.label,
          itemValue: has(applicant2Details, "nationality")
            ? applicant2Details.nationality
            : null,
        },
      ]}
    />
  );
};

export default Applicant2PreviewList;
